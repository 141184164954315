import { lazy, mixed, object, ValidationError } from 'yup'
import type { InferType } from 'yup'
import { automationArrayConditionObjectSchema } from './ArrayCondition/ArrayCondition.interface.ts'
import { automationBooleanConditionObjectSchema } from './BooleanCondition/BooleanCondition.interface.ts'
import { automationDateTimeConditionObjectSchema } from './DateTimeCondition/DateTimeCondition.interface.ts'
import { automationNullConditionObjectSchema } from './NullCondition/NullCondition.interface.ts'
import { automationNumericConditionObjectSchema } from './NumericCondition/NumericCondition.interface.ts'
import { automationStringConditionObjectSchema } from './StringCondition/StringCondition.interface.ts'

export enum OperatorEnum {
  // Generic
  EQUALS = 0,
  NOT_EQUALS = 1,

  // String & Array
  CONTAINS = 2,
  NOT_CONTAINS = 3,

  // String
  STARTS_WITH = 4,
  NOT_STARTS_WITH = 5,
  ENDS_WITH = 6,
  NOT_ENDS_WITH = 7,
  MATCHES_REGEX = 8,
  NOT_MATCHES_REGEX = 9,

  // Array
  IS_EMPTY = 10,
  IS_NOT_EMPTY = 11,
  LENGTH_EQUALS = 12,
  LENGTH_NOT_EQUALS = 13,
  LENGTH_GREATER_THAN = 14,
  LENGTH_GREATER_THAN_OR_EQUALS = 15,
  LENGTH_LESS_THAN = 16,
  LENGTH_LESS_THAN_OR_EQUALS = 17,

  // Object
  IS_NULL = 18,
  IS_NOT_NULL = 19,

  // DateTime & Numeric
  GREATER_THAN = 20,
  GREATER_THAN_OR_EQUALS = 21,
  LESS_THAN = 22,
  LESS_THAN_OR_EQUALS = 23,

  // Boolean
  IS_TRUE = 24,
  IS_FALSE = 25,
}

export enum AutomationConditionTypesEnum {
  ArrayCondition = 'Condition.Array',
  DateTimeCondition = 'Condition.DateTime',
  BooleanCondition = 'Condition.Boolean',
  NullCondition = 'Condition.Null',
  NumericCondition = 'Condition.Numeric',
  StringCondition = 'Condition.String',
}

export function automationConditionTypesEnumOptions(t: (key: string) => string) {
  return (Object.values(AutomationConditionTypesEnum) as string[])
    .filter(v => v.includes('.'))
    .map(value => ({
      value,
      label: t(`automations.condition.type.options.${value.replaceAll('.', '')}`),
    }))
}

export function automationConditionObjectSchema(t: (v: string) => string) {
  return object({
    condition: lazy((value) => {
      switch (value?.['#type']) {
        case AutomationConditionTypesEnum.NullCondition:
          return automationNullConditionObjectSchema(t)
        case AutomationConditionTypesEnum.ArrayCondition:
          return automationArrayConditionObjectSchema(t)
        case AutomationConditionTypesEnum.StringCondition:
          return automationStringConditionObjectSchema(t)
        case AutomationConditionTypesEnum.NumericCondition:
          return automationNumericConditionObjectSchema(t)
        case AutomationConditionTypesEnum.DateTimeCondition:
          return automationDateTimeConditionObjectSchema(t)
        case AutomationConditionTypesEnum.BooleanCondition:
          return automationBooleanConditionObjectSchema(t)
        default:
          // Define a schema that will always fail validation for the default case
          return object({
            '#type': mixed()
              .oneOf(
                Object.values(AutomationConditionTypesEnum).filter(v =>
                  v.includes('.'),
                ),
              )
              .required(t('common.forms.required')),
          }).test('invalid-conditionType', 'Invalid conditionType', (_) => {
            return new ValidationError('Invalid condition type')
          })
      }
    }),
  })
}

export type AutomationConditionObjectSchema = InferType<
  ReturnType<typeof automationConditionObjectSchema>
>
