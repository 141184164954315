import { lazy, mixed, object, ValidationError } from 'yup'
import type { InferType } from 'yup'
import {
  AbstractValueTypeEnum,
  entityReferenceObjectSchema,
  expressionValueObjectSchema,
  fixedValueObjectSchema,
  referenceValueObjectSchema,
  stringTemplateObjectSchema,
} from '../../../../../../../composables'

export function automationValueObjectSchema(
  t: ReturnType<typeof useI18n>['t'],
  {
    allowedTypes,
    required = true,
  }: {
    allowedTypes?: AbstractValueTypeEnum[]
    parentValidators?: any
    required?: boolean
  } = {},
) {
  const { userPreferences } = useAuthStore()

  return lazy((value) => {
    const type = value?.['#type']
    if (allowedTypes && !allowedTypes.includes(type)) {
      return object({
        '#type': required
          ? mixed().oneOf(allowedTypes).required(t('common.forms.invalid_type'))
          : mixed(),
      }).test('invalid-valueType', 'Invalid valueType', (_) => {
        return required ? new ValidationError('Invalid valueType type') : true
      })
    }

    switch (value?.['#type']) {
      case AbstractValueTypeEnum.FixedValue:
        return fixedValueObjectSchema(t)
      case AbstractValueTypeEnum.EntityReference:
        return entityReferenceObjectSchema(t)
      case AbstractValueTypeEnum.ReferenceValue:
        return referenceValueObjectSchema(t)
      case AbstractValueTypeEnum.ExpressionValue:
        return expressionValueObjectSchema(t)
      case AbstractValueTypeEnum.StringTemplate:
        return stringTemplateObjectSchema(t, {
          locale: userPreferences.value?.locale
            ? userPreferences.value?.locale.toUpperCase()
            : 'NL',
        })
      default:
        // Define a schema that will always fail validation for the default case
        return object({
          '#type': mixed()
            .oneOf(
              allowedTypes
              ?? Object.values(AbstractValueTypeEnum).filter(v =>
                v.includes('.'),
              ),
            )
            .required(
              allowedTypes
                ? t('common.forms.invalid_type')
                : t('common.forms.required'),
            ),
        }).test('invalid-valueType', 'Invalid valueType', (_) => {
          return new ValidationError('Invalid valueType type')
        })
    }
  })
}

export type AutomationValueObjectSchema = InferType<
  ReturnType<typeof automationValueObjectSchema>
>

export function automationValueSimpleObjectSchema(
  t: ReturnType<typeof useI18n>['t'],
  {
    allowedTypes,
    required = true,
  }: {
    allowedTypes?: (Exclude<AbstractValueTypeEnum, AbstractValueTypeEnum.StringTemplate>)[]
    required?: boolean
  } = {},
) {
  return lazy((value) => {
    const type = value?.['#type']
    if (allowedTypes && !allowedTypes.includes(type)) {
      return object({
        '#type': required
          ? mixed().oneOf(allowedTypes).required(t('common.forms.invalid_type'))
          : mixed(),
      }).test('invalid-valueType', 'Invalid valueType', (_) => {
        return required ? new ValidationError('Invalid valueType type') : true
      })
    }

    switch (value?.['#type']) {
      case AbstractValueTypeEnum.FixedValue:
        return fixedValueObjectSchema(t)
      case AbstractValueTypeEnum.EntityReference:
        return entityReferenceObjectSchema(t)
      case AbstractValueTypeEnum.ReferenceValue:
        return referenceValueObjectSchema(t)
      case AbstractValueTypeEnum.ExpressionValue:
        return expressionValueObjectSchema(t)
      default:
        // Define a schema that will always fail validation for the default case
        return object({
          '#type': mixed()
            .oneOf(
              allowedTypes
              ?? Object.values(AbstractValueTypeEnum).filter(v =>
                v.includes('.'),
              ),
            )
            .required(
              allowedTypes
                ? t('common.forms.invalid_type')
                : t('common.forms.required'),
            ),
        }).test('invalid-valueType', 'Invalid valueType', (_) => {
          return new ValidationError('Invalid valueType type')
        })
    }
  })
}

export type AutomationValueSimpleObjectSchema = InferType<
  ReturnType<typeof automationValueSimpleObjectSchema>
>
