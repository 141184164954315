import { StorageSerializers } from '@vueuse/core'

export enum GlobalDefaultParamsKeysEnum {
  CaseFiles = 'case-files',
  Clients = 'clients',
}

export const useGlobalDefaultParams = createGlobalState(() => {
  const defaultParams = useStorage<Record<GlobalDefaultParamsKeysEnum, URLSearchParams>>('default-params', {} as any, undefined, {
    serializer: StorageSerializers.object,
  })
  const resetAllDefaultParams = () => {
    defaultParams.value = {} as any
  }

  const resetDefaultParams = (key: GlobalDefaultParamsKeysEnum) => {
    defaultParams.value[key] = new URLSearchParams()
  }

  const upsertDefaultParams = (key: GlobalDefaultParamsKeysEnum, params: URLSearchParams, {
    reset,
    resetAll,
  }: {
    reset?: boolean
    resetAll?: boolean
  } = {}) => {
    if (resetAll) {
      resetAllDefaultParams()
    }
    if (!defaultParams.value[key] || reset) {
      resetDefaultParams(key)
    }
    params.forEach((value, k) => {
      defaultParams.value[key].set(k, value)
    })
  }

  return {
    defaultParams,
    upsertDefaultParams,
    resetAllDefaultParams,
    resetDefaultParams,
  }
})
