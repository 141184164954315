<script setup lang="ts">
import { isDebugToolsEnabled } from './debugtools.ts'

const DebugContainer = defineAsyncComponent(() => import('./DebugToolsContainer.vue'))
const keys = useMagicKeys()
const ctrlShiftDT = keys['ctrl+shift+d+t']

whenever(ctrlShiftDT, () => {
  isDebugToolsEnabled.value = !isDebugToolsEnabled.value
})
</script>

<template>
  <Dialog
    v-model:visible="isDebugToolsEnabled" :pt="{
      content: 'p-0',
      root: 'h-95-percent debug-modal',
    }"
    dismissable-mask
    modal header="Debug Tools"
    class="debug-modal"
    :style="{ width: '90rem' }"
    :breakpoints="{ '1199px': '90vw', '575px': '90vw' }"
  >
    <template v-if="isDebugToolsEnabled">
      <DebugContainer />
    </template>
  </Dialog>
</template>

<style>
.debug-modal .p-dialog-header {
  padding: 1rem 1rem !important;
}
</style>
