import { StorageSerializers } from '@vueuse/core'
import { CaseFileSortEnum, useCaseFileStore } from '../case_files'
import { DebtorType } from '../debtor'
import { GlobalDefaultParamsKeysEnum, useGlobalDefaultParams } from '../defaultParams/useGlobalDefaultParams.ts'
import { AdministrationIdEnum } from './brand_type.interface.ts'

export enum BrandTypes {
  Debtt = 'Debtt',
  DebttLegal = 'DebttLegal',
  DebttManagement = 'DebttManagement',
  Collect4u = 'Collect4u',
}

export const BrandTypeOptions: Record<BrandTypes, {
  administration: AdministrationIdEnum
  administrationIri: string
  sort?: CaseFileSortEnum
  debtorType?: DebtorType
}> = {
  [BrandTypes.Debtt]: {
    administration: AdministrationIdEnum.Debtt,
    administrationIri: `/api/administrations/${AdministrationIdEnum.Debtt}`,
    sort: CaseFileSortEnum.COLLECTION,
    debtorType: DebtorType.Organization,
  },
  [BrandTypes.DebttLegal]: {
    administration: AdministrationIdEnum.Debtt,
    administrationIri: `/api/administrations/${AdministrationIdEnum.Debtt}`,
    sort: CaseFileSortEnum.LEGAL,
    debtorType: DebtorType.Organization,
  },
  [BrandTypes.DebttManagement]: {
    administration: AdministrationIdEnum.DebttManagement,
    administrationIri: `/api/administrations/${AdministrationIdEnum.DebttManagement}`,
    sort: CaseFileSortEnum.CREDIT_MANAGEMENT,
    debtorType: DebtorType.Organization,
  },
  [BrandTypes.Collect4u]: {
    administration: AdministrationIdEnum.Collect4u,
    administrationIri: `/api/administrations/${AdministrationIdEnum.Collect4u}`,
    sort: CaseFileSortEnum.COLLECTION,
    debtorType: DebtorType.Individual,
  },

}

export const allBrandTypes = [
  BrandTypes.Debtt,
  BrandTypes.DebttLegal,
  BrandTypes.DebttManagement,
  BrandTypes.Collect4u,
]

export const useBrandTypeStore = createGlobalState(() => {
  const selectedBrandType = useStorage<BrandTypes | null>('selected-brand-type', null, undefined, {
    serializer: StorageSerializers.string,
  })
  const { items } = useCaseFileStore()
  const { returnUrl } = useAuthStore()
  const router = useRouter()
  const switchBrandType = (
    brandType: BrandTypes,
    redirect: boolean = false,
  ) => {
    selectedBrandType.value = brandType
    if (redirect) {
      if (returnUrl.value) {
        router.replace(returnUrl.value).then()
      }
      else {
        if (window.history.state.back === null) {
          router.replace('/dashboard').then()
        }
        else {
          router.back()
        }
      }
    }
    if (returnUrl.value) {
      returnUrl.value = null
    }
  }
  const { upsertDefaultParams, resetAllDefaultParams } = useGlobalDefaultParams()
  watch(() => selectedBrandType.value, (brandType) => {
    if (brandType) {
      const caseFileParams = new URLSearchParams()
      items.value = []
      const options = BrandTypeOptions[brandType]
      caseFileParams.set('administration[]', `/api/administrations/${options.administration}`)
      if (options.sort) {
        caseFileParams.set('sort[]', `${options.sort}`)
      }

      upsertDefaultParams(GlobalDefaultParamsKeysEnum.CaseFiles, caseFileParams, { resetAll: true })
    }
    else {
      resetAllDefaultParams()
    }
  }, { immediate: true })

  const currentBrandTypeOptions = computed(() => {
    return selectedBrandType.value ? BrandTypeOptions[selectedBrandType.value] : null
  })

  return {
    currentBrandTypeOptions,
    selectedBrandType,
    switchBrandType,
  }
})
