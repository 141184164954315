import type { MediaObjectHydraCollectionItem } from './media_objects.interface.ts'

export const useMediaObjectsStore = createGlobalState(() => {
  const items = ref<MediaObjectHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  return {
    reset,
    items,
    loading,
    initialLoading,
  }
})
