import app from '@core/configApp.ts'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

app.use(FloatingVue, {
  themes: {
    'access-denied-tooltip': {
      $extend: 'tooltip',
      // Other options (see the 'Global options' section)
    },
  },
})
