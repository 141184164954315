import type { CustomExportHydraCollectionItem } from './custom_exports.interface.ts'

export const useCustomExportsStore = createGlobalState(() => {
  const items = ref<CustomExportHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  return {
    reset,
    items,
    loading,
    initialLoading,
  }
})
