import type { InternalAxiosRequestConfig } from 'axios'
import { InterceptorType } from '../../createApi.ts'
import type { InterceptorConfig } from '../../createApi.ts'

export function LegacyUrlPrefix(): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url)
      return config

    if (config.url.startsWith('/api') || config.url.includes('/oauth/v2'))
      return config

    config.url = `/api/v1${config.url}`

    return config
  }

  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  }
}
