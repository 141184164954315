import dayjs from 'dayjs'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import type { ComputedRef, Ref } from 'vue'
import type { LocationQuery } from 'vue-router'
import { FilterMatchMode } from '../../common'
import { getFilterParamKey } from './table.helpers.ts'
import type { FiltersType } from './table.interface.ts'

export function useFilters<Entity>({
  filters: defaultFilters,
  onClear,
  query,
}: {
  filters?: FiltersType<Entity>
  onClear?: () => void
  query?: LocationQuery
}): {
    showFilters: Ref<boolean>
    clearFilter: () => void
    isFiltering: ComputedRef<boolean>
    filters: Ref<FiltersType<Entity>>
  } {
  const filters = ref(cloneDeep(defaultFilters)) as Ref<FiltersType<Entity>>
  const showFilters = ref(true)

  function clearFilter() {
    if (defaultFilters)
      filters.value = cloneDeep(defaultFilters)

    if (onClear)
      onClear()
  }

  watch(() => query, (v) => {
    if (v) {
      Object.entries(v).forEach(([key, value]) => {
        const filterKeys = Object.entries(filters.value).map(([key, value]) => {
          return {
            key,
            value,
            getInitialValue: value.getInitialValue,
            filterKey: getFilterParamKey('multiSearchKey' in value && value.multiSearchKey ? value.multiSearchKey(value) : value.searchKey || key, value.matchMode as any) as string | string[],
          }
        })
        const filter = filterKeys.find((fk) => {
          if (typeof fk.filterKey === 'string') {
            return fk.filterKey === key
          }
          return fk.filterKey.includes(key)
        })
        if (filter) {
          let newValue
          switch (filter.value.matchMode) {
            case FilterMatchMode.EXISTS_DROPDOWN:
            case FilterMatchMode.EXISTS:
              newValue = value === 'true' ? true : value === 'false' ? false : undefined
              break
            case FilterMatchMode.EQUALS:
            case FilterMatchMode.BOOLEAN:
            case FilterMatchMode.CONTAINS:
            case FilterMatchMode.MULTI_DROPDOWN:
            case FilterMatchMode.DROPDOWN:
              if (filter.getInitialValue) {
                newValue = filter.getInitialValue(value as string)
              }
              else {
                newValue = value as string
              }
              break
            case FilterMatchMode.NUMBER:
              newValue = Number.parseInt(value as string)
              break
            case FilterMatchMode.PERCENTAGE:
            case FilterMatchMode.CURRENCY:
              newValue = Number.parseInt(value as string) / 100
              break
            case FilterMatchMode.DATE_BETWEEN:
              // eslint-disable-next-line no-case-declarations
              const currentValue: Date[] = filter.value.value ? Array.from(filter.value.value) : []
              // eslint-disable-next-line no-case-declarations
              const index = key.includes('after') ? 0 : 1
              currentValue[index] = dayjs(value as string).toDate()
              newValue = currentValue
              break
            case FilterMatchMode.DATE_AFTER:
            case FilterMatchMode.DATE_BEFORE:
              newValue = dayjs(value as string).toDate()
              break
          }
          if (newValue !== undefined)
            filters.value[filter.key as keyof FiltersType<Entity>]!.value = newValue
        }
      })
    }
  }, { immediate: true })

  // watch filters and delete params from url when the filter value went from a value to null
  // watch(() => filters.value, (newFilters, oldFilters) => {
  //   if (!oldFilters)
  //     return
  //
  //   Object.entries(newFilters).forEach(([key, newFilter]) => {
  //     const oldFilter = oldFilters[key as keyof FiltersType<Entity>]
  //     if (newFilter.value === null && oldFilter?.value !== null) {
  //       const routeQuery = { ...route.query }
  //       const filterParamKey = getFilterParamKey(key, newFilter.matchMode as any) as string | string[]
  //       if (typeof filterParamKey === 'string')
  //         delete routeQuery[filterParamKey]
  //       else
  //         filterParamKey.forEach(paramKey => delete routeQuery[paramKey])
  //
  //       router.replace({ path: route.path, query: routeQuery }).then()
  //     }
  //   })
  // }, { deep: true })

  const isFiltering = computed(() => !isEqual(filters.value, defaultFilters))
  return {
    filters,
    showFilters,
    clearFilter,
    isFiltering,
  }
}
