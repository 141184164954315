import { AxiosError } from 'axios'

class NetworkError extends AxiosError {
  constructor(
        message: string = 'base.api.network_error',
  ) {
    super(message)
    this.message = message
    this.name = 'NetworkError'
    if ('captureStackTrace' in Error)
      Error.captureStackTrace(this, NetworkError)
  }
}

export {
  NetworkError,
}
