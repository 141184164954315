import type { InternalAxiosRequestConfig } from 'axios'
import { InterceptorType } from '../../createApi.ts'
import type { InterceptorConfig } from '../../createApi.ts'

export function AcceptLanguageRequestInterceptor(
  localeGetter: () => string,
  excludedRoutes: string[] = [],
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url)
      return config

    if (excludedRoutes.includes(config.url))
      return config

    const locale = localeGetter()
    if (locale) {
      config.headers['Accept-Language'] = localeGetter()
    }
    return config
  }

  return {
    name: 'AcceptHeaderRequestInterceptor',
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  }
}
