import { AxiosError } from 'axios'
import type { ComputedRef, Ref } from 'vue'
import type { FinancialStateHydraItem } from '../../../../composables'

export function useCaseFileTotals(caseFileId: string | number | Ref<number> | ComputedRef<string | undefined>, init: boolean = true) {
  const totals = ref<null | FinancialStateHydraItem>(null)
  const loading = ref(false)
  const { onError } = useServerErrorHandler()

  const getCaseFileTotals = async (id?: number) => {
    if (loading.value)
      return
    try {
      loading.value = true
      const { data } = await api.get<FinancialStateHydraItem>(`/api/case_files/${id || (isRef(caseFileId) ? caseFileId.value : caseFileId)}/financial_state`)
      totals.value = data
    }
    catch (e) {
      if (e instanceof AxiosError && e.response?.status === 403) {
        return
      }
      onError(e)
    }
    finally {
      loading.value = false
    }
  }

  onMounted(() => {
    if (init)
      getCaseFileTotals().then()
  })

  const totalAmount = computed(() => {
    return totals.value?.totalAmount || 0
  })

  return {
    totalAmount,
    totals,
    loading,
    getCaseFileTotals,
  }
}
