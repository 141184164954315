import type { DataTableSortEvent } from 'primevue/datatable'
import type { StateHandler } from 'v3-infinite-loading/lib/types'
import type { ComputedRef, Ref } from 'vue'
import type { FilterValue } from './table.helpers.ts'

export enum ColumnsTypeEnum {
  Default = 'default',
  Multi = 'multi',
  Expand = 'expand',
}

export const columnsType = {
  default: ColumnsTypeEnum.Default,
  multi: ColumnsTypeEnum.Multi,
}

export const PER_PAGE = 25
export type ComputedColumnsType<T> = ColumnsType<T> & {
  visible: boolean
  position: number
  type: ColumnsTypeEnum
}[]
export type ColumnsType<T> = {
  field: keyof T | string
  header: string
  dataIndex?: string
  disableSort?: boolean
  sortKey?: string
  style?: string
  visible?: boolean
  type?: ColumnsTypeEnum
  position?: number
}[]
export interface TableLoadOptions {
  state?: StateHandler
  reset?: boolean
  fullReset?: boolean
  refreshLastPage?: boolean
}
export interface BaseServerTableInterface<T> {
  items: Ref<T[]>
  defaultSort?: Pick<DataTableSortEvent, 'sortOrder' | 'sortField'>
  columns: Ref<ColumnsType<T>>
  selectedItems: Ref<T[]>
  expandedRows: Ref<T[]>
  loading: Ref<boolean>
  initialLoading?: Ref<boolean>
  lazyEvent: Ref<DataTableSortEvent | null>
  nextPage: (options?: TableLoadOptions) => Promise<void>
  prevPage?: (options?: TableLoadOptions) => void
  page?: Ref<number>
  onRowSelectionUpdate?: (selection: T[]) => void
  hasNext?: Ref<number>
  disable: ComputedRef<boolean>
  hasPerms: ComputedRef<boolean>
  hasMulti?: boolean | Ref<boolean>
  hasExpand?: boolean
  loadData: (options?: TableLoadOptions) => Promise<void>
}

export interface ServerTableFilterAndSortInterface<T>
  extends BaseServerTableInterface<T> {
  isFiltering: ComputedRef<boolean>
  clearFilter: () => void
  tableCallError: Ref<'access_denied' | 'not_found' | null>
  onSort: (event: DataTableSortEvent) => any
  filters: Ref<FiltersType<T>>
  showSorting: Ref<boolean>
  showFilters: Ref<boolean>
}

export type FiltersType<Entity> = Partial<Record<keyof Entity, FilterValue<Entity>>> & Record<'global', FilterValue<Entity>>
