import { usePrimeVue } from 'primevue/config'

export enum ThemeEnum {
  Light = 'light',
  Dark = 'dark',
}

function extractMiddlePart(inputString: string) {
  const match = inputString.match(/\/themes\/(.*?)\/theme\.css/)

  if (match && match.length > 1)
    return match[1] as ThemeEnum
  else
    throw new Error('No match found')
}

function getActiveTheme(): ThemeEnum {
  const currentHref = document.querySelector('#theme-link')?.getAttribute('href')
  return currentHref ? extractMiddlePart(currentHref) : ThemeEnum.Light
}

export function useTheme() {
  const loading = ref(true)

  const PrimeVue = usePrimeVue()
  const mode = useColorMode()
  watch(
    [
      () => mode.value,
    ],
    ([m]) => {
      if (['testing'].includes(import.meta.env.MODE)) {
        loading.value = false
        return
      }
      const newTheme = m === 'dark' ? ThemeEnum.Dark : ThemeEnum.Light
      if (newTheme === getActiveTheme()) {
        loading.value = false
      }
      else {
        PrimeVue.changeTheme(getActiveTheme(), newTheme, 'theme-link', () => {
          setTimeout(() => {
            loading.value = false
          }, 50)
        })
      }
    },
    {
      immediate: true,
    },
  )
  return {
    loading,
  }
}
