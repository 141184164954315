// useExport.ts
import { SimpleOperators } from '@features/export/export.interface.ts'
import { findDeepestNode } from '@features/export/fields/field.helpers.ts'
import { createInjectionState } from '@vueuse/shared'
import { cloneDeep } from 'lodash-es'
import { useToast } from 'primevue/usetoast'
import type { ExportEntitySchema, ExportFieldsSchema, ExportFilterSchema } from '@features/export/export.helpers.ts'
import type { ExportTypeEnum, FieldSchema } from '@features/export/export.interface.ts'
import type { TreeNode } from 'primevue/treenode'

const initialStepData = {
  entity: {
    data: {
      entity: '',
    },
    isValid: false,
    reset: false,
  },
  fields: {
    data: {
      fields: {},
    },
    isValid: false,
    reset: false,
  },
  filters: {
    data: {
      filters: [],
    },
    isValid: false,
    reset: false,
  },
  summary: {
    data: null,
    isValid: false,
  },
}
export const steps = ['entity', 'fields', 'filters', 'summary'] as const
const [useProvideExport, useExportRaw] = createInjectionState(() => {
  const step = ref<(typeof steps)[number]>('entity')
  const nodes = ref<TreeNode[]>([])
  const loading = ref(false)
  const toast = useToast()
  const { t } = useI18n()
  const { onError } = useServerErrorHandler()
  const router = useRouter()
  const relationOriginalValueMapping = ref<Record<string, any>>({})
  // state
  const stepData = reactive<{
    entity: {
      data: ExportEntitySchema
      isValid: boolean
      reset: boolean
    }
    fields: {
      data: ExportFieldsSchema
      isValid: boolean
      reset: boolean
    }
    filters: {
      data: ExportFilterSchema
      isValid: boolean
      reset: boolean
    }
    summary: {
      data: null
      isValid: boolean
    }
  }>(cloneDeep(initialStepData))

  const nextStep = () => {
    const index = steps.indexOf(step.value)
    if (index < steps.length - 1)
      step.value = steps[index + 1]
  }
  const prevStep = () => {
    const index = steps.indexOf(step.value)
    if (index > 0)
      step.value = steps[index - 1]
  }

  const isCurrentStep = (stepName: (typeof steps)[number]) =>
    step.value === stepName

  const isStepValid = (stepName: (typeof steps)[number]) =>
    stepData[stepName].isValid

  const updateStepValidity = (
    stepName: (typeof steps)[number],
    isValid: boolean,
  ) => {
    stepData[stepName].isValid = isValid
  }

  const getStepData = (stepName: (typeof steps)[number]) => ({
    ...stepData[stepName],
    index: steps.indexOf(stepName),
  })

  const setCurrentStep = (stepName: (typeof steps)[number]) => {
    step.value = stepName
  }

  const isValid = computed(() => {
    return Object.values(stepData).every(step => step.isValid)
  })

  const isNextStep = (stepName: (typeof steps)[number]) => {
    return steps.indexOf(stepName) === steps.indexOf(step.value) + 1
  }

  const isPreviousStepValid = (stepName: (typeof steps)[number]) => {
    const previousStep = steps[steps.indexOf(stepName) - 1]
    return stepData[previousStep]?.isValid
  }
  const isLastStep = (stepName: (typeof steps)[number]) => {
    return steps.indexOf(stepName) === steps.length - 1
  }

  function getParentsNames(key: string) {
    if (key.includes('.')) {
      const keys = key.split('.')
      console.log(keys)
      console.log('sliced', keys.slice(0, keys.length - 1))
      // return keys except last one
      return keys
        .slice(0, keys.length - 1)
        .map((_, index) => {
          const finalKey = keys.slice(0, index + 1).join('.')
          const field = stepData.fields.data.fields[finalKey]
          if (!field || field.hide === true)
            return undefined
          return field.label
        })
        .filter(v => !!v)
        .join(' - ')
    }
    else {
      return ''
    }
  }

  const startExport = async (type: ExportTypeEnum) => {
    if (loading.value)
      return
    try {
      loading.value = true
      const data = {
        type,
        entity: stepData.entity.data.entity,
        attributes: Object.entries(stepData.fields.data.fields)
          .filter(([key, value]) => {
            const node = findDeepestNode(nodes.value, key)
            return (value as any).label && !node?.children
          })
          .reduce(
            (acc, [key, value]) => {
              const val = value as FieldSchema
              const label = val.label
              const parentName = getParentsNames(key)
              const finalLabel = parentName
                ? `${parentName} - ${label}`
                : label
              acc[finalLabel] = key
              return acc
            },
            {} as Record<string, string>,
          ),
        filters: stepData.filters.data.filters
          ? stepData.filters.data.filters.reduce(
            (acc, filter) => {
              const { field, operator, value } = filter
              acc[field] = [operator]
              if (
                operator !== SimpleOperators.IS_NULL
                && operator !== SimpleOperators.IS_NOT_NULL
              ) {
                acc[field].push(value as string)
              }

              return acc
            },
            {} as Record<string, string[]>,
          )
          : {},
      }

      const { data: anyData } = await api
        .post('/api/export', data)
        .catch((e) => {
          console.log(e, 'HAME')
          throw new Error('Oeps!')
        })
      console.log(anyData)
      toast.add({
        severity: 'success',
        summary: t('exports.export.success_title'),
        detail: t('exports.export.success_description'),
        life: 3000,
      })

      step.value = 'entity'
      Object.assign(stepData, cloneDeep(initialStepData))
      stepData.entity.reset = true
      stepData.fields.reset = true
      stepData.filters.reset = true
      router.replace({
        name: 'exports',
      }).then()
    }
    catch (e) {
      console.log(e)
      onError(e)
      throw e
    }
    finally {
      loading.value = false
    }
  }

  return {
    relationOriginalValueMapping,
    loading,
    startExport,
    stepData,
    step,
    getParentsNames,
    nextStep,
    isPreviousStepValid,
    nodes,
    prevStep,
    isCurrentStep,
    isStepValid,
    updateStepValidity,
    getStepData,
    isValid,
    setCurrentStep,
    isNextStep,
    isLastStep,
  }
})
export { useProvideExport }

// If you want to hide `useExport` and wrap it in default value logic or throw error logic, please don't export `useExport`
export function useExport() {
  const counterStore = useExportRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideExport` on the appropriate parent component',
    )
  }
  return counterStore
}
