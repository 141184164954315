export enum InstanceType {
  CMS = 1,
  PORTAL = 2,
}

export const useInstanceStore = createGlobalState(
  () => {
    const instanceType = ref<InstanceType>(InstanceType.CMS)
    const testing = ref<boolean>(false)

    function setInstanceType(type: InstanceType) {
      console.log('setInstanceType', type)
      instanceType.value = type
    }

    function setIsTesting(value: boolean) {
      console.log('setIsTesting', value)
      testing.value = value
    }

    const isCms = computed(() => instanceType.value === InstanceType.CMS)
    const isPortal = computed(() => instanceType.value === InstanceType.PORTAL)

    return { instanceType, setInstanceType, isCms, isPortal, setIsTesting, testing }
  },
)
