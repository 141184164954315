import { StorageSerializers, useStorage } from '@vueuse/core'
import type { UserStatisticsHydraItem } from './dashboard.interface.ts'

export const useUserStatisticsStore = createGlobalState(() => {
  const loading = ref(false)
  const initialLoading = ref(false)
  const userStats = useStorage<Record<string, UserStatisticsHydraItem | null>>('user-stats', {}, undefined, { serializer: StorageSerializers.object })
  const globalStats = useStorage<UserStatisticsHydraItem | null>('global-user-stats', null, undefined, { serializer: StorageSerializers.object })
  const statsLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    statsLoading.value = false
    userStats.value = {}
    globalStats.value = null
  }

  function setStatsForUser(userIri: string, data: UserStatisticsHydraItem) {
    userStats.value[userIri] = data
  }

  function getStatsForUser(userIri: string) {
    return userStats.value[userIri]
  }

  function resetStatsForUser(userIri: string) {
    userStats.value[userIri] = null
  }

  return {
    userStats,
    reset,
    getStatsForUser,
    resetStatsForUser,
    globalStats,
    setStatsForUser,
    statsLoading,
    loading,
    initialLoading,
  }
})
