import { StorageSerializers, useStorage } from '@vueuse/core'
import type { UnitHydraCollectionItem } from './units.interface.ts'

export const useUnitsStore = createGlobalState(() => {
  const units = useStorage<Record<string, UnitHydraCollectionItem> | null>('units', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<UnitHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    units.value = null
  }

  const { hasPermission } = usePermissions()

  const allowReadUnits = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.UNIT
        .READ_COLLECTION,
    ),
  )
  async function setUnits(): Promise<void> {
    if (!allowReadUnits.value) {
      units.value = null
      return
    }
    await getAll<UnitHydraCollectionItem>({ url: '/api/units' }).then((data: UnitHydraCollectionItem[]) => {
      units.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, UnitHydraCollectionItem>)
      return data
    })
  }

  const unitsOptions = computed(() => {
    return units.value
      ? Object.values(units.value).map(status => ({
        label: status.name,
        value: status.name,
      }))
      : []
  })

  return {
    unitsOptions,
    allowReadUnits,
    setUnits,
    reset,
    items,
    units,
    loading,
    initialLoading,
  }
})
