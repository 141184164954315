import { boolean, mixed, object } from 'yup'
import type { InferType } from 'yup'

import { AutomationConditionTypesEnum } from '../automation_condition_edit.interface.ts'
import { automationValueObjectSchema } from '../Value/condition_value.interface.ts'

export enum StringOperatorEnum {
  EQUALS = 0,
  NOT_EQUALS = 1,
  CONTAINS = 2,
  NOT_CONTAINS = 3,
  STARTS_WITH = 4,
  NOT_STARTS_WITH = 5,
  ENDS_WITH = 6,
  NOT_ENDS_WITH = 7,
  MATCHES_REGEX = 8,
  NOT_MATCHES_REGEX = 9,
}

export function stringOperatorEnumOptions(t: (key: string) => string) {
  return Object.values(StringOperatorEnum).filter(v => typeof v === 'number').map(value => ({
    value,
    label: t(`automations.condition.operator.options.${value}`),
  }))
}

export function automationStringConditionObjectSchema(t: (v: string) => string) {
  return object({
    'leftValue': automationValueObjectSchema(t),
    'operator': mixed()
      .oneOf(
        Object.values(StringOperatorEnum).filter(v => typeof v === 'number'),
        t('common.forms.enum'),
      )
      .required(t('common.forms.required')),
    'rightValue': automationValueObjectSchema(t),
    '#type': mixed()
      .oneOf([AutomationConditionTypesEnum.StringCondition])
      .required(t('common.forms.required'))
      .default(AutomationConditionTypesEnum.StringCondition),
    'caseSensitive': boolean().default(false),
  })
}

export type AutomationStringConditionObjectSchema = InferType<
  ReturnType<typeof automationStringConditionObjectSchema>
>
