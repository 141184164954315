import type { Ref } from 'vue'
import type { TableLoadOptions } from './table.interface.ts'

export function usePaginate({
  hasNext,
  loadData,
  page,
}: {
  hasNext: Ref<number>
  page: Ref<number>
  loadData: (options?: TableLoadOptions) => Promise<void>
}): {
    nextPage: (options?: TableLoadOptions) => Promise<void>
    prevPage: (options?: TableLoadOptions) => Promise<void>
    page: Ref<number>
  } {
  async function nextPage(options: TableLoadOptions = {
    reset: false,
  }) {
    if (hasNext.value) {
      page.value = page.value + 1
      await loadData(options)
    }
    else {
      options.state?.complete()
    }
  }

  async function prevPage(options: TableLoadOptions = {
    reset: false,
  }) {
    if (page.value > 1) {
      page.value = page.value - 1
      await loadData(options)
    }
  }

  return {
    nextPage,
    page,
    prevPage,
  }
}
