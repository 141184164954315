export interface RecentRoute {
  type: 'casefile' | 'client' | 'debtor'
  id: string | number
  extraRouteParams?: any
  name: string
}

export type Recent = RecentRoute

export const useRecentStore = createGlobalState(
  () => {
    const recents = useStorage<Recent[]>('recently-viewed-items', [])

    const MAX_RECENTS = 10 // Maximum number of recent items

    const addRecent = (recent: Recent) => {
      // Check if the recent item already exists
      const existingIndex = recents.value.findIndex(item =>
        `${item.type}-${item.id}` === `${recent.type}-${recent.id}`,
      )

      if (existingIndex !== -1) {
        // If the recent item already exists, remove it
        recents.value.splice(existingIndex, 1)
      }

      // Add the recent item to the beginning of the array
      recents.value = [recent, ...recents.value.slice(0, MAX_RECENTS - 1)]
    }

    function reset() {
      recents.value = []
    }

    return {
      reset,
      recents,
      addRecent,
    }
  },
)
