import { StorageSerializers, useStorage } from '@vueuse/core'
import type { TaskListHydraCollectionItem } from './task_lists.interface.ts'

export const useTaskListStore = createGlobalState(() => {
  const taskLists = useStorage<Record<
    string,
    TaskListHydraCollectionItem
  > | null>('task_lists', null, undefined, {
    serializer: StorageSerializers.object,
  })

  const items = ref<TaskListHydraCollectionItem[]>([])
  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  const { hasPermission } = usePermissions()

  const allowReadTaskLists = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.TASK_LIST
        .READ_COLLECTION,
    ),
  )

  async function setTaskLists(): Promise<void> {
    if (!allowReadTaskLists.value) {
      taskLists.value = null
      return
    }
    await getAll<TaskListHydraCollectionItem>({
      url: '/api/task_lists',
    }).then((data: TaskListHydraCollectionItem[]) => {
      taskLists.value = data.reduce(
        (acc, item) => {
          acc[item['@id']] = item
          return acc
        },
        {} as Record<string, TaskListHydraCollectionItem>,
      )
      return data
    })
  }

  const taskListsOptions = computed(() => {
    return taskLists.value
      ? Object.values(taskLists.value).map(status => ({
        label: status.name,
        value: status['@id'],
      }))
      : []
  })

  function getTaskListLabel(iri: string) {
    if (!taskLists.value)
      return '-'
    const taskList = taskLists.value[iri]
    return taskList ? taskList.name : '-'
  }

  function getTaskList(iri: string) {
    if (!taskLists.value)
      return null
    const taskList = taskLists.value[iri]
    return taskList || null
  }

  const taskListsItems = computed<TaskListHydraCollectionItem[]>(() => {
    return taskLists.value
      ? Object.values(taskLists.value)
      : []
  })

  return {
    taskListsItems,
    reset,
    allowReadTaskLists,
    getTaskListLabel,
    getTaskList,
    taskLists,
    setTaskLists,
    taskListsOptions,
    items,
    loading,
    initialLoading,
  }
})
