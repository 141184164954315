export function findCidValues(htmlString: string): string[] {
  // Parse the HTML string into a document
  const parser = new DOMParser()
  const document = parser.parseFromString(htmlString, 'text/html')

  // Select all img elements from the document
  const imgElements = document.querySelectorAll('img')

  // Initialize an array to store the cid values
  const cidValues: string[] = []

  // Loop through all the img elements
  imgElements.forEach((img) => {
    // Get the src attribute of the img element
    const src = img.getAttribute('src')

    // Check if the src attribute contains "cid:"
    if (src && src.startsWith('cid:')) {
      // Extract the value after "cid:" and add it to the array
      cidValues.push(src.substring(4))
    }
  })

  // Return the array of cid values
  return cidValues
}
