import type { TreeNode } from 'primevue/treenode'
import type { HydraContext, HydraTimeBased } from '../../../common'

export enum MediaObjectScopeEnum {
  AUTOMATION = 1,
  CORRESPONDENCE_TEMPLATE = 2,
}

export function mediaObjectScopeEnumOptions(v: ReturnType<typeof useI18n>['t']) {
  return Object.values(MediaObjectScopeEnum)
    .filter(v => typeof v === 'number')
    .map((scope) => {
      return {
        label: v(`media.library.scopes.${scope}`),
        value: scope,
      }
    })
}

export interface MediaFolderParentItem {
  parent?: null
  scope: MediaObjectScopeEnum
}

export interface MediaFolderChildItem {
  parent: MediaFolderHydraItem
}

export type BaseMediaFolderItem = MediaFolderParentItem | MediaFolderChildItem

export type MediaFolderHydraItem = HydraTimeBased &
  BaseMediaFolderItem &
  HydraContext & {
    '@type': 'MediaFolder'
    'name': string
  }

export type MediaFolderHydraCollectionItem = MediaFolderHydraItem

export type MediaFolderTreeNode = TreeNode & {
  scope?: MediaObjectScopeEnum
  item?: MediaFolderHydraItem
}
