import type { Ref } from 'vue'

export function useSorting(): {
  showSorting: Ref<boolean>
} {
  const showSorting = ref(true)

  return {
    showSorting,
  }
}
