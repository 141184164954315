import type { ComputedRef } from 'vue'
import { useInitOriginalValueCache } from './useInitOriginalValueCache.ts'
import type { HydraContext } from '../../../../common'
import type { AutomationV1ObjectSchema } from './automationv1.interface.ts'
import type { MappedEntity } from './Editor/Mapping/mapping.interface.ts'

const [useProvideOriginalValueCache, useOriginalValueCacheRaw] = createInjectionState(
  ({ mappedEntity, automation }: {
    mappedEntity: ComputedRef<MappedEntity | null>
    automation: ComputedRef<AutomationV1ObjectSchema>
  }) => {
    const cache = ref<Record<string, HydraContext>>({})
    const arrayCache = ref<Record<string, HydraContext[]>>({})
    const upsert = (key: string, value: HydraContext) => {
      cache.value[key] = value
    }

    const setArrayCache = (key: string, value: HydraContext[]) => {
      arrayCache.value[key] = value
    }

    const remove = (key: string) => {
      delete cache.value[key]
    }

    const removeArrayCache = (key: string) => {
      delete arrayCache.value[key]
    }

    const reset = () => {
      cache.value = {}
      arrayCache.value = {}
    }
    const { init } = useInitOriginalValueCache({ upsert, mappedEntity, automation, cache, setArrayCache, arrayCache })

    return {
      cache,
      arrayCache,
      setArrayCache,
      removeArrayCache,
      upsert,
      remove,
      reset,
      init,
    }
  },
)

export { useProvideOriginalValueCache }

export function useOriginalValueCache() {
  const counterStore = useOriginalValueCacheRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideOriginalValueCache` on the appropriate parent component',
    )
  }
  return counterStore
}
