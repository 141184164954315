import { type InferType, lazy, mixed, object, ValidationError } from 'yup'
import { AbstractValueTypeEnum } from './documents.interface.ts'
import {
  entityReferenceObjectSchema,
  expressionValueObjectSchema,
  fixedValueObjectSchema,
  referenceValueObjectSchema,
  stringTemplateObjectSchema,
} from './documents.schema.ts'

export function embeddedPartDocumentSchema(
  t: (v: string) => string,
  { overrideSchema }: {
    overrideSchema?: {
      mediaObject?: Partial<Record<AbstractValueTypeEnum, any>>
      contentIdentifier?: Partial<Record<AbstractValueTypeEnum, any>>
    }
  } = {},
) {
  return object({
    mediaObject: lazy((value) => {
      switch (value?.['#type']) {
        case AbstractValueTypeEnum.ReferenceValue:
          return overrideSchema?.mediaObject?.[AbstractValueTypeEnum.ReferenceValue] ?? referenceValueObjectSchema(t)
        case AbstractValueTypeEnum.EntityReference:
          return overrideSchema?.mediaObject?.[AbstractValueTypeEnum.EntityReference] ?? entityReferenceObjectSchema(t)
        default:
          // Define a schema that will always fail validation for the default case
          return object({
            '#type': mixed()
              .oneOf(
                Object.values(AbstractValueTypeEnum).filter(v =>
                  v.includes('.'),
                ),
              )
              .required(t('common.forms.required')),
          }).test('invalid-conditionType', 'Invalid conditionType', (_) => {
            return new ValidationError('Invalid condition type')
          })
      }
    }),
    contentIdentifier: lazy((value) => {
      switch (value?.['#type']) {
        case AbstractValueTypeEnum.ReferenceValue:
          return overrideSchema?.contentIdentifier?.[AbstractValueTypeEnum.ReferenceValue] ?? referenceValueObjectSchema(t)
        case AbstractValueTypeEnum.FixedValue:
          return overrideSchema?.contentIdentifier?.[AbstractValueTypeEnum.FixedValue] ?? fixedValueObjectSchema(t)
        case AbstractValueTypeEnum.ExpressionValue:
          return overrideSchema?.contentIdentifier?.[AbstractValueTypeEnum.ExpressionValue] ?? expressionValueObjectSchema(t)
        case AbstractValueTypeEnum.StringTemplate:
          return overrideSchema?.contentIdentifier?.[AbstractValueTypeEnum.StringTemplate] ?? stringTemplateObjectSchema(t)
        default:
          // Define a schema that will always fail validation for the default case
          return object({
            '#type': mixed()
              .oneOf(
                Object.values(AbstractValueTypeEnum).filter(v =>
                  v.includes('.'),
                ),
              )
              .required(t('common.forms.required')),
          }).test('invalid-conditionType', 'Invalid conditionType', (_) => {
            return new ValidationError('Invalid condition type')
          })
      }
    }),
  })
}
export type EmbeddedPartDocumentSchema = InferType<ReturnType<typeof embeddedPartDocumentSchema>>
