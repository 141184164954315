export enum FilterMatchMode {
  CONTAINS = 'contains',
  CURRENCY = 'currency',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  EXISTS = 'exists',
  BOOLEAN = 'boolean',
  EQUALS = 'equals',
  DROPDOWN = 'dropdown',
  MULTI_DROPDOWN = 'multi_dropdown',
  EXISTS_DROPDOWN = 'exists_dropdown',
  EXISTS_TRUE_VALUE_DROPDOWN = 'exists_true_value_dropdown',
  IN = 'in',
  DATE_BEFORE = 'dateBefore',
  DATE_AFTER = 'dateAfter',
  DATE_BETWEEN = 'dateBetween',
}

export enum TableFiltersTypeEnum {
  Dropdown = 'dropdown',
}
export enum FilterOperator {
  AND = 'and',
  OR = 'or',
}
