import type { InternalAxiosRequestConfig } from 'axios'
import { InterceptorType } from '../../createApi.ts'
import type { InterceptorConfig } from '../../createApi.ts'

export function contentTypeInterceptor({
  excludedRoutes,
}: {
  excludedRoutes?: string[]
} = {}): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url)
      return config

    if (excludedRoutes && excludedRoutes.includes(config.url))
      return config

    config.headers = config.headers || {}

    if ((config.method?.toLowerCase())) {
      if (config.method?.toLowerCase() === 'patch') {
        // Set the Content-Type header to 'application/merge-patch+json'
        config.headers['Content-Type'] = 'application/merge-patch+json'
      }

      if (['post', 'put'].includes(config.method?.toLowerCase())) {
        // Set the Content-Type header to 'application/merge-patch+json'
        config.headers['Content-Type'] = 'application/ld+json'
      }
    }

    return config
  }

  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  }
}
