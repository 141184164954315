import * as Sentry from '@sentry/vue'
import { createGlobalState, StorageSerializers } from '@vueuse/core'
import type { Ref } from 'vue'
import { setLocale } from '../core'

import { getPermissionFieldKey } from '../features/permissions/getPermissionFieldKey.ts'
import type { HydraCollection, UserRole } from '../../common'
import type {
  ClientHydraCollectionItem,
  ClientHydraItem,
  UserHydraItem,
  UserPermissionHydraCollectionItem,
} from '../features'

export const useAuthStore = createGlobalState(() => {
  const token = useStorage<string | null>('token', null, sessionStorage, {
    serializer: StorageSerializers.string,
  })
  const returnUrl = useStorage<string | null>('return-url', null, undefined, {
    serializer: StorageSerializers.string,
  })
  const refreshToken = useStorage<string | null>(
    'refresh_token',
    null,
    sessionStorage,
    { serializer: StorageSerializers.string },
  )
  const refreshTokenRemembered = useStorage<string | null>(
    'refresh-token',
    null,
    undefined,
    { serializer: StorageSerializers.string },
  )
  const preferredClient = useStorage<{
    'name': string
    'id': number
    '@id': string
  } | null>('prefered-client', null, undefined, {
    serializer: StorageSerializers.object,
  })
  const hasMultipleClients = useStorage<boolean>('has-multiple-clients', false)
  const user = useStorage<UserHydraItem | null>('user', null, sessionStorage, {
    serializer: StorageSerializers.object,
  })
  // const { clearPermCache, getPermCache, setPermCache } = usePermsCache()
  let userPermissions: Ref<string[]>
  let permissions: Ref<Record<string, string[]> | null>
  if (import.meta.env.VITE_ALL_PERMISSIONS === 'true') {
    userPermissions = ref<string[]>([
      PermissionAttributes.GLOBAL.FIELD['*'],
      PermissionAttributes.GLOBAL.OPERATION['*'],
    ])
    permissions = ref<Record<string, string[]> | null>({
      '/api/clients/1': [
        // PermissionAttributes.CONTEXT.CLIENT.OPERATION.THIRD_PARTY.READ_COLLECTION_VIA_CASE_FILE,
      ],
    })
  }
  else {
    permissions = useStorage<Record<string, string[]> | null>(
      'permissions',
      null,
      undefined,
      {
        serializer: StorageSerializers.object,
      },
    )

    userPermissions = useStorage<string[]>(
      'user-permissions',
      [],
    )
  }

  // watch(
  //   [() => permissions.value, () => userPermissions.value],
  //   () => {
  //     clearPermCache()
  //   },
  //   {
  //     deep: true,
  //   },
  // )
  const userPreferences = useStorage<{
    locale: string
    timezone: string
  }>(
    'user-preferences',
    {
      locale: 'NL',
      timezone: 'Europe/Amsterdam',
    },
    undefined,
    { serializer: StorageSerializers.object },
  )

  const setUserPreferences = (user: UserHydraItem | null) => {
    if (user === null)
      return

    if (user.locale) {
      userPreferences.value.locale = user.locale.toUpperCase()
    }
    if (user.timezone) {
      userPreferences.value.timezone = user.timezone
    }
  }
  const isAuthenticated = computed(() => {
    return token.value !== null
  })

  const isRemembered = computed(() => {
    return refreshTokenRemembered.value !== null
  })

  const hasToken = computed(() => {
    return token.value !== null
  })

  const getRefreshToken = computed(() => {
    return refreshToken.value
      ? refreshToken.value
      : refreshTokenRemembered.value
  })

  const getAuthHeadersObject = computed(() => {
    const headers: Record<string, string | null> = {
      Authorization: `Bearer ${token.value}`,
    }

    return headers
  })
  const tokenPayload = computed(() => {
    if (token.value === null)
      return null

    const base64Url = token.value.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  })

  function setToken(t: string) {
    token.value = t
  }

  function setUser(userInput: UserHydraItem) {
    user.value = {
      ...userInput,
      '@id': `/api/users/${userInput.id}`,
    }
    setUserPreferences(userInput)
    setLocale().then()
  }

  async function initUser() {
    const { data: u } = await api.get<UserHydraItem>('/api/users/me')
    setUser(u)

    if (import.meta.env.PROD)
      Sentry.setUser(u)
  }

  async function initPermissions() {
    if (import.meta.env.VITE_ALL_PERMISSIONS !== 'true') {
      const userIri = user.value?.['@id']
      if (!userIri)
        return
      const { data } = await api.get<
        HydraCollection<UserPermissionHydraCollectionItem>
      >('/api/users/me/permissions')
      permissions.value = {}
      userPermissions.value = []
      for (const permission of data['hydra:member']) {
        if (!permissions.value) {
          return
        }

        const validKeys = getPermissionFieldKey(permission)

        if (validKeys.length < 1) {
          userPermissions.value = permission.attributes
        }
        else {
          for (const key of validKeys) {
            if (permission[key] && typeof permission[key] === 'string') {
              permissions.value[permission[key] as string] = permission.attributes
            }
          }
        }
      }
    }
  }

  function setPreferredClient(
    clientInput: ClientHydraCollectionItem | ClientHydraItem | null,
    override: boolean = false,
  ) {
    if (clientInput === null) {
      preferredClient.value = null
      return
    }
    if (preferredClient.value === null || override) {
      preferredClient.value = {
        'id': clientInput.id,
        'name': clientInput.name ?? '',
        '@id': clientInput['@id'],
      }
    }
  }

  function setHasMultipleClients(hasMultipleClientsInput: boolean) {
    hasMultipleClients.value = hasMultipleClientsInput
  }

  function reset() {
    token.value = null
    refreshToken.value = null
    preferredClient.value = null
    user.value = null
    refreshTokenRemembered.value = null
    permissions.value = null
    userPermissions.value = []
    returnUrl.value = null
  }

  function setRefreshToken(
    refresh: string,
    remembered: boolean = isRemembered.value,
  ) {
    refreshToken.value = refresh

    if (remembered)
      refreshTokenRemembered.value = refreshToken.value
  }

  function fakeLogin() {
    token.value = 'fakeToken'
    refreshToken.value = 'fakeRefreshToken'
  }

  const hasRole = (role: UserRole): boolean => {
    return user.value?.roles.includes(role) ?? false
  }

  return {
    initUser,
    hasRole,
    fakeLogin,
    reset,
    returnUrl,
    setPreferredClient,
    setUser,
    preferredClient,
    userPermissions,
    permissions,
    hasMultipleClients,
    refreshTokenRemembered,
    initPermissions,
    setHasMultipleClients,
    userPreferences,
    user,
    hasToken,
    tokenPayload,
    setToken,
    getRefreshToken,
    getAuthHeadersObject,
    setRefreshToken,
    token,
    isRemembered,
    refreshToken,
    isAuthenticated,
  }
})
