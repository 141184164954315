import dayjs from 'dayjs'
import { array, boolean, date, mixed, object, string } from 'yup'
import type { InferType } from 'yup'
import { BulkClearValue } from '../../../components/BulkActions/bulk_actions.interface.ts'
import { CaseFileSortEnum } from './case_file.interface.ts'
import type { CaseFileHydraCollectionItem, CaseFileHydraItem } from './case_file.interface.ts'

enum BulkToManyOperationEnum {
  ADD = 1,
  REMOVE = 2,
  SET = 3,
}

export interface CaseFileBulkWriteData {
  data?: Partial<
    Omit<CaseFileHydraItem, 'collectionCostGroup' | 'debtor' | 'creditor' | 'closeReason'>
  > & {
    collectionCostGroup?: string
    debtor?: string
    creditor?: string
    closeReason?: string | null
  }
}

export interface CaseFileBulkWriteListsOptions {
  lists?: string[]
  listOperation?: BulkToManyOperationEnum
}

export type CaseFileBulkWriteOptions =
  | CaseFileBulkWriteData
  | CaseFileBulkWriteListsOptions
export type CaseFileBulkWrite = CaseFileBulkWriteOptions & {
  iris: string[]
}
export const caseFileBulkObjectSchema = object({
  closeReason: string().optional(),
  status: string().optional(),
  sort: mixed().oneOf(Object.values(CaseFileSortEnum)).optional(),
  confirmedAt: date().nullable().default(null).optional(),
  administration: string().optional(),
  collectionCostGroup: string().optional(),
  executor: string().optional(),
  closedAt: boolean().optional().default(false),
  individual: string().optional(),
  organization: string().optional(),
  creditor: string().optional(),
  addLists: array().of(string()).optional().default([]),
  removeLists: array().of(string()).optional().default([]),
  setLists: array().of(string()).optional().default([]),
})

export type CaseFileBulkObjectSchema = InferType<
  typeof caseFileBulkObjectSchema
>

export function useCaseFileBulkTransformer() {
  const outputTransformer = (
    item: CaseFileBulkObjectSchema,
    items: CaseFileHydraCollectionItem[],
  ): CaseFileBulkWrite => {
    console.log('item', item)
    return {
      iris: items.map(item => `/api/case_files/${item.id}`),
      data: {
        sort:
          item.sort !== undefined ? (item.sort as CaseFileSortEnum) : undefined,
        confirmedAt: item.confirmedAt !== undefined
          ? dayjs(item.confirmedAt).format('YYYY-MM-DDTHH:mm:ss[Z]')
          : undefined,
        closedAt: item.closedAt !== undefined ? item.closedAt ? dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]') : undefined : undefined,
        debtor: item.organization !== undefined ? item.organization : item.individual !== undefined ? item.individual : undefined,
        creditor: item.creditor !== undefined ? item.creditor : undefined,
        collectionCostGroup: item.collectionCostGroup !== undefined ? item.collectionCostGroup : undefined,
        status: item.status !== undefined ? item.status : undefined,
        closeReason: item.closeReason !== undefined ? item.closeReason === BulkClearValue ? null : item.closeReason : undefined,
        administration: item.administration !== undefined ? item.administration : undefined,
        executor: item.executor !== undefined ? item.executor : undefined,
      },
      lists:
        item.addLists && item.addLists.length > 0
          ? item.addLists as string[]
          : item.removeLists && item.removeLists.length > 0
            ? item.removeLists as string[]
            : item.setLists && item.setLists.length > 0
              ? item.setLists as string[]
              : undefined,
      listOperation:
        item.addLists && item.addLists.length > 0
          ? BulkToManyOperationEnum.ADD
          : item.removeLists && item.removeLists.length > 0
            ? BulkToManyOperationEnum.REMOVE
            : item.setLists && item.setLists.length > 0
              ? BulkToManyOperationEnum.SET
              : undefined,
    }
  }

  return {
    outputTransformer,
  }
}
