import { mixed, object } from 'yup'
import type { InferType } from 'yup'

import { AutomationConditionTypesEnum } from '../automation_condition_edit.interface.ts'
import { automationValueObjectSchema } from '../Value/condition_value.interface.ts'

export enum NullOperatorEnum {
  IS_NULL = 18,
  IS_NOT_NULL = 19,
}

export function nullOperatorEnumOptions(t: (key: string) => string) {
  return Object.values(NullOperatorEnum).filter(v => typeof v === 'number').map(value => ({
    value,
    label: t(`automations.condition.operator.options.${value}`),
  }))
}

export function automationNullConditionObjectSchema(t: (v: string) => string) {
  return object({
    'leftValue': automationValueObjectSchema(t),
    'operator': mixed()
      .oneOf(
        Object.values(NullOperatorEnum).filter(v => typeof v === 'number'),
        t('common.forms.enum'),
      )
      .required(t('common.forms.required')),
    '#type': mixed()
      .oneOf([AutomationConditionTypesEnum.NullCondition])
      .required(t('common.forms.required'))
      .default(AutomationConditionTypesEnum.NullCondition),
  })
}

export type AutomationNullConditionObjectSchema = InferType<
  ReturnType<typeof automationNullConditionObjectSchema>
>
