import type { RouteRecordRaw } from 'vue-router'

export const TasksRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'tasks',
    component: () => import('./Tasks.vue'),
  },
  // {
  //   path: '/tasks/:id', // Define a route for individual case files
  //   name: 'tasks',
  //   component: Task, // Replace with the actual component for individual case files
  // },
]

export default TasksRoutes
