export interface FieldSchema {
  checked: boolean
  label: string
  originalLabel: string
  hide?: boolean
}

export enum ExportTypeEnum {
  CSV = 1,
  ZIP = 2,
}

export interface FieldSchemaEmpty {
  checked?: boolean
  label?: string
  originalLabel?: string
  hide?: boolean
}
export const EntityTypeCurrency = 'currency'
export const EntityTypeDate = 'date'
export const EntityTypeString = 'string'
export const EntityTypeBoolean = 'boolean'
export const EntityTypeNumber = 'number'
export const EntityTypeEnum = 'enum'
export const EntityTypeTimezone = 'timezone'
export const EntityTypeLocale = 'locale'
export const EntityTypeRelation = 'relation'
export enum SimpleOperators {
  IS_NOT_NULL = 'IS NOT NULL',
  IS_NULL = 'IS NULL',
}

export enum Operator {
  EQUAL = '=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  NOT_EQUAL = '<>',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
}
export interface initialFieldType {
  key: string
  label: string
  children?: initialFieldType[]
}
export type initialFieldTypes = initialFieldType[]
