import { mixed, object } from 'yup'
import type { InferType } from 'yup'

import { AutomationConditionTypesEnum } from '../automation_condition_edit.interface.ts'
import { automationValueObjectSchema } from '../Value/condition_value.interface.ts'

export enum ArrayOperatorEnum {
  CONTAINS = 2,
  NOT_CONTAINS = 3,
  IS_EMPTY = 10,
  IS_NOT_EMPTY = 11,
  LENGTH_EQUALS = 12,
  LENGTH_NOT_EQUALS = 13,
  LENGTH_GREATER_THAN = 14,
  LENGTH_GREATER_THAN_OR_EQUALS = 15,
  LENGTH_LESS_THAN = 16,
  LENGTH_LESS_THAN_OR_EQUALS = 17,
}

export function arrayOperatorEnumOptions(t: (key: string) => string) {
  return Object.values(ArrayOperatorEnum).filter(v => typeof v === 'number').map(value => ({
    value,
    label: t(`automations.condition.operator.options.${value}`),
  }))
}

export function automationArrayConditionObjectSchema(t: (v: string) => string) {
  return object({
    'haystack': automationValueObjectSchema(t),
    'operator': mixed()
      .oneOf(
        Object.values(ArrayOperatorEnum).filter(v => typeof v === 'number'),
        t('common.forms.enum'),
      )
      .required(t('common.forms.required')),
    'needleOrValue': automationValueObjectSchema(t),
    '#type': mixed()
      .oneOf([AutomationConditionTypesEnum.ArrayCondition])
      .required(t('common.forms.required'))
      .default(AutomationConditionTypesEnum.ArrayCondition),
  })
}

export type AutomationArrayConditionObjectSchema = InferType<
  ReturnType<typeof automationArrayConditionObjectSchema>
>
