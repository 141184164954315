import { mixed, object, string } from 'yup'
import type { InferType } from 'yup'
import type { HydraContext } from '../../../common'

export enum CloseReasonTypeEnum {
  COLLECTED = 1,
  INCOLLECTABLE = 2,
}

export function closeReasonOptions(t: (v: string) => string): {
  label: string
  value: CloseReasonTypeEnum
}[] {
  return [
    {
      label: t('case_file.close_reasons.type.options.1'),
      value: CloseReasonTypeEnum.COLLECTED,
    },
    {
      label: t('case_file.close_reasons.type.options.2'),
      value: CloseReasonTypeEnum.INCOLLECTABLE,
    },
  ]
}

export interface CaseFileCloseReasonHydraItem extends HydraContext {
  name: string
  description?: string
  type?: CloseReasonTypeEnum
}

export type CaseFileCloseReasonHydraCollectionItem = CaseFileCloseReasonHydraItem

export function caseFileCloseReasonEditSchema() {
  return object({
    name: string().required().default(''),
    description: string().optional().default(''),
    type: mixed().oneOf(Object.values(CloseReasonTypeEnum)).optional().nullable(),
  })
}

export type CaseFileCloseReasonObjectSchema = InferType<ReturnType<typeof caseFileCloseReasonEditSchema>>

export interface CaseFileCloseReasonWrite {
  name: string
  description?: string
  type?: CloseReasonTypeEnum
}
