import type { ClientHydraItem } from '../../../../composables'
import type { indexMapping } from './useCaseFileEdit.ts'

export function useCaseFileEditPermissions({
  creditor,
}: {
  creditor: Ref<ClientHydraItem | null>
}) {
  const { hasPermission } = usePermissions()

  const allowReadAdministration = computed(() =>
    true,
  )
  const showActivities = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.ACTIVITY.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })

  const showCaseFileExtraCosts = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.CASE_FILE_DEBTOR_EXTRA_COST.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })

  const showCaseFileThirdParties = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.THIRD_PARTY.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })

  const allowChange = computed(() =>
    creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.CASE_FILE.UPDATE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false,
  )

  const showCaseFileMediaObjects = computed(() => {
    return hasPermission(PermissionAttributes.CONTEXT.OPERATION.MEDIA_OBJECT.READ_COLLECTION_VIA_CASE_FILE, {
      CLIENT: creditor.value?.['@id'],
    })
  })

  const showCaseFileCreditorInvoices = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.GLOBAL.OPERATION.INVOICE.READ_COLLECTION_VIA_ADMINISTRATION, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const showCaseFileDebtorExtraCosts = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.CASE_FILE_DEBTOR_EXTRA_COST.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const showCaseFileDebtorInvoices = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.CASE_FILE_DEBTOR_INVOICE.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const showCaseFilePayments = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.PAYMENT.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const showCaseFileRemittances = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.PAYOUT.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const showCaseFileTasks = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.GLOBAL.OPERATION.TASK.READ_COLLECTION_VIA_CASE_FILE)
      : false
  })
  const showCaseFilePayouts = computed(() => {
    return creditor.value?.['@id']
      ? hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.PAYMENT_PLAN_GROUP.READ_COLLECTION_VIA_CASE_FILE, {
        CLIENT: creditor.value?.['@id'],
      })
      : false
  })
  const mappedActiveTabs = computed<Record<keyof typeof indexMapping, boolean>>(() => {
    return {
      0: showCaseFileTasks.value,
      1: showActivities.value,
      2: showActivities.value,
      3: showActivities.value,
      4: showCaseFileMediaObjects.value,
      5: showCaseFileCreditorInvoices.value,
      6: showCaseFileDebtorInvoices.value,
      7: showCaseFileDebtorExtraCosts.value,
      8: showCaseFilePayments.value,
      9: showCaseFileThirdParties.value,
      10: showCaseFileRemittances.value,
      11: showCaseFilePayouts.value,
    }
  })
  return {
    mappedActiveTabs,
    showCaseFileTasks,
    showCaseFileRemittances,
    showCaseFileThirdParties,
    allowChange,
    showCaseFileDebtorInvoices,
    showCaseFilePayments,
    showActivities,
    showCaseFileExtraCosts,
    allowReadAdministration,
    showCaseFileMediaObjects,
    hasPermission,
  }
}
