import type { Ref } from 'vue'
import { usePermissionEditorOptions } from './usePermissionEditorOptions'
import type { PermissionTabItem } from './permissions_editor.interface'

const [useProvidePermissionEditor, usePermissionEditorRaw]
  = createInjectionState(
    ({
      currentIdentifier,
      items,
    }: {
      currentIdentifier: Ref<string>
      items: PermissionTabItem[]
    }) => {
      const { mode } = usePermissionEditorOptions()
      const values = useFormValues()

      onMounted(() => {
        updateDeeply()
      })

      type ExtendedPermissionTabItem = PermissionTabItem & {
        parent: ExtendedPermissionTabItem | null
        depth: number // Add a depth property to track how deep the item is in the hierarchy
      }

      const deepNodes = computed(() => {
        const deepNodesRaw: ExtendedPermissionTabItem[] = []

        if (!items || items.length === 0) {
          return deepNodesRaw
        }

        function traverseItems(
          items: PermissionTabItem[],
          parent: ExtendedPermissionTabItem | null = null,
          depth: number = 0,
        ) {
          items.forEach((item) => {
            // Extend item with a parent and depth property
            const itemWithParent: ExtendedPermissionTabItem = {
              ...item,
              parent,
              depth,
            }
            // Add item with parent and depth info to deepNodes
            deepNodesRaw.push(itemWithParent) // Push to accumulate and later sort by depth
            // Recursively traverse children, incrementing the depth
            if ('children' in item) {
              traverseItems(item.children, itemWithParent, depth + 1)
            }
          })
        }

        // Start traversal
        traverseItems(items)

        // Sort the deepNodes array based on depth in descending order
        deepNodesRaw.sort((a, b) => b.depth - a.depth)

        return deepNodesRaw
      })

      const updateNodeValue = (node: PermissionTabItem, value: boolean) => {
        values.value.permissions[currentIdentifier.value][node.id] = value ? true : undefined
        if (node.dependentIds) {
          node.dependentIds.forEach((id) => {
            values.value.permissions[currentIdentifier.value][id] = value ? true : undefined
          })
        }
      }

      function checkIfAllChildrenChecked(
        node: ExtendedPermissionTabItem,
      ): boolean {
        if ('children' in node) {
          return node.children.every(
            child => values.value.permissions[currentIdentifier.value][child.id],
          )
        }
        return false
      }

      function checkifSomeChildrenChecked(
        node: ExtendedPermissionTabItem,
      ): boolean {
        if ('children' in node) {
          return node.children.some(
            child => values.value.permissions[currentIdentifier.value][child.id] && !child.disableParentBased,
          )
        }
        return false
      }

      function uncheckChildren(node: PermissionTabItem): void {
        if ('children' in node) {
          node.children.forEach((child) => {
            if (!child.disableParentBased) {
              updateNodeValue(child, false)
              uncheckChildren(child)
            }
          })
        }
      }
      function isCheckedByAnyParent(
        node: ExtendedPermissionTabItem,
      ): ExtendedPermissionTabItem | null {
        if (node.parent) {
          if (values.value.permissions[currentIdentifier.value][node.parent.id]) {
            return node.parent
          }
          if (node.parent.disableParentBased) {
            return null
          }
          return isCheckedByAnyParent(node.parent)
        }
        return null
      }

      function uncheckParents(node: ExtendedPermissionTabItem, updatedItem: string): void {
        updateNodeValue(node, false)
        checkAllChildExceptUpdatedItem(node, updatedItem)
        const checkedByParent = isCheckedByAnyParent(node)
        if (checkedByParent) {
          uncheckParents(checkedByParent, updatedItem)
        }
      }

      function checkAllChildExceptUpdatedItem(
        node: PermissionTabItem,
        updatedItem: string,
      ): void {
        if ('children' in node) {
          node.children.forEach((child) => {
            updateNodeValue(child, child.id !== updatedItem)
            checkAllChildExceptUpdatedItem(child, updatedItem)
          })
        }
      }

      function toggleDisabledByParentBasedChildren(
        node: PermissionTabItem,
        value: boolean = false,
        hadParentChecked: boolean = false,
      ): void {
        if ('children' in node) {
          node.children.forEach((child) => {
            if (child.disableParentBased || hadParentChecked) {
              updateNodeValue(child, value)
              toggleDisabledByParentBasedChildren(child, value, true)
            }
            else {
              toggleDisabledByParentBasedChildren(child, value)
            }
          })
        }
      }

      function findNodeById(id: string): ExtendedPermissionTabItem | undefined {
        return deepNodes.value.find(node => node.id === id)
      }

      const checkAllChildrenExceptAstriks = (node: PermissionTabItem, value: boolean) => {
        if ('children' in node) {
          node.children.forEach((child) => {
            if (!child.id.includes('*')) {
              updateNodeValue(child, value)
            }
            checkAllChildrenExceptAstriks(child, value)
          })
        }
      }

      const checkIfAllChildrenAreCheckedOrHasAstriks = (node: PermissionTabItem): boolean => {
        // deeply
        if ('children' in node) {
          return node.children.every((child) => {
            return checkIfAllChildrenAreCheckedOrHasAstriks(child) && (values.value.permissions[currentIdentifier.value][child.id] || child.id.includes('*'))
          })
        }
        else {
          return true
        }
      }

      function updateDeeply(updatedItem?: string): void {
        let updatedNode: ExtendedPermissionTabItem | undefined
        if (mode.value === 'asteriks') {
          if (updatedItem) {
            updatedNode = findNodeById(updatedItem)
            //* First we check if the updateNode is checked by any parent
            const checkedByAnyParent = updatedNode
              ? isCheckedByAnyParent(updatedNode)
              : null
            //* If the updateNode is checked by any parent, we need to uncheck it and all its children
            if (checkedByAnyParent && updatedNode?.parent) {
              //* We uncheck parent deeply, and all its children except the updated item.
              uncheckParents(checkedByAnyParent, updatedItem)
            }

            if (!updatedNode?.disableParentBased && updatedNode) {
              toggleDisabledByParentBasedChildren(
                updatedNode,
                values.value.permissions[currentIdentifier.value][updatedItem],
              )
            }

            if (checkedByAnyParent && updatedNode) {
              uncheckChildren(updatedNode)
            }
          }
          // Display each item's id, parent id, and depth
          for (const node of deepNodes.value) {
            //

            if (node.id === updatedItem) {
              uncheckChildren(node)
            }

            const allChildrenChecked = checkIfAllChildrenChecked(node)
            if (allChildrenChecked) {
              updateNodeValue(node, true)
              uncheckChildren(node)
            }

            const someChildrenChecked = !allChildrenChecked && checkifSomeChildrenChecked(node)
            if (someChildrenChecked && node.id !== updatedItem && node.parent) {
              updateNodeValue(node, false)
            }
          }

          if (updatedNode && updatedNode?.dependentIds && updatedItem) {
            for (const id of updatedNode.dependentIds) {
              values.value.permissions[currentIdentifier.value][id] = values.value.permissions[currentIdentifier.value][updatedItem] ? true : undefined
            }
          }
        }
        else {
          if (updatedItem) {
            updatedNode = findNodeById(updatedItem)
            if (updatedNode) {
              if ('children' in updatedNode) {
                // check if all children are checked
                const allChildrenChecked = checkIfAllChildrenAreCheckedOrHasAstriks(updatedNode)
                checkAllChildrenExceptAstriks(updatedNode, !allChildrenChecked)
              }
            }
          }
          // for (const node of deepNodes.value) {
          //
          // }
        }
      }

      return {
        values,
        deepNodes,
        updateDeeply,
        currentIdentifier,
      }
    },
  )
export { useProvidePermissionEditor }

// If you want to hide `usePermissionsTableHelper` and wrap it in default value logic or throw error logic, please don't export `usePermissionsTableHelper`
export function usePermissionEditor() {
  const permissionsStateRaw = usePermissionEditorRaw()
  if (permissionsStateRaw == null) {
    throw new Error(
      'Please call `useProvidePermissionEditor` on the appropriate parent component',
    )
  }
  return permissionsStateRaw
}
