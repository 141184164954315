import type { Component } from 'vue'
import type { MappingTypesEnum } from '../../../components/Features/automations/edit/Editor/Mapping/MappingTypesEnum.ts'
import type { CorrespondenceTemplateContextEnum } from '../correspondences'
import type { ViewContextEnum } from '../queue_job'
import type {
  EntityReferenceValueObjectSchema,
  ExpressionValueObjectSchema,
  FixedValueObjectSchema,
  ReferenceValueObjectSchema,
  StringTemplateObjectSchema,
} from './documents.schema.ts'

export enum AbstractValueTypeEnum {
  FixedValue = 'Value.Fixed',
  ReferenceValue = 'Value.Reference',
  ExpressionValue = 'Value.Expression',
  StringTemplate = 'Value.StringTemplate',
  EntityReference = 'Value.EntityReference',
}
export function abstractValueTypeEnumOptions(t: (key: string) => string) {
  return Object.values(AbstractValueTypeEnum).filter(v => v.includes('.')).map(value => ({
    value,
    label: t(`common.document.${value}.label`),
  }))
}

export interface DocumentTypeComponentsStringTemplate {
  templateType?: Component
  template?: Component
  locale?: {
    [AbstractValueTypeEnum.FixedValue]?: Component
  }
  renderer?: Component
}

export interface DocumentTypeComponents {
  [AbstractValueTypeEnum.FixedValue]?: {
    value?: Component
  }
  [AbstractValueTypeEnum.EntityReference]?: {
    iri?: Component
  }
  [AbstractValueTypeEnum.ReferenceValue]?: {
    reference?: Component
  }
  [AbstractValueTypeEnum.ExpressionValue]?: {
    expression?: Component
  }
  [AbstractValueTypeEnum.StringTemplate]?: DocumentTypeComponentsStringTemplate
}

export interface DocumentTypeTransformers {
  [AbstractValueTypeEnum.StringTemplate]?: {
    input?: (val: StringTemplateObjectSchema) => StringTemplateObjectSchema
  }
  [AbstractValueTypeEnum.EntityReference]?: Record<string, never>
  [AbstractValueTypeEnum.FixedValue]?: Record<string, never>
  [AbstractValueTypeEnum.ExpressionValue]?: Record<string, never>
}

export interface DocumentTypeFormatters {
  [AbstractValueTypeEnum.EntityReference]?: (val: EntityReferenceValueObjectSchema) => Promise<string>
  [AbstractValueTypeEnum.FixedValue]?: (val: FixedValueObjectSchema) => Promise<string>
  [AbstractValueTypeEnum.ExpressionValue]?: (val: ExpressionValueObjectSchema) => Promise<string>
  [AbstractValueTypeEnum.ReferenceValue]?: (val: ReferenceValueObjectSchema) => Promise<string>
  [AbstractValueTypeEnum.StringTemplate]?: (val: StringTemplateObjectSchema) => Promise<string>
}
export interface DocumentTypeIcons {
  [AbstractValueTypeEnum.EntityReference]?: string[]
  [AbstractValueTypeEnum.FixedValue]?: string[]
  [AbstractValueTypeEnum.ExpressionValue]?: string[]
  [AbstractValueTypeEnum.ReferenceValue]?: string[]
  [AbstractValueTypeEnum.StringTemplate]?: string[]
}

export interface DocumentTypeOptionsStringTemplate {
  template?: {
    editorType?: 'document' | 'text'
    templateType?: 'text' | 'email' | 'pdf'
    baseContext?: CorrespondenceTemplateContextEnum | null
    administration?: string | null
    viewContext?: ViewContextEnum | null
  }
  renderer?: {
    hidden?: boolean
  }
}

export interface DocumentTypeOptions {
  [AbstractValueTypeEnum.EntityReference]?: Record<string, never>
  [AbstractValueTypeEnum.FixedValue]?: Record<string, never>
  [AbstractValueTypeEnum.ExpressionValue]?: Record<string, never>
  [AbstractValueTypeEnum.StringTemplate]?: DocumentTypeOptionsStringTemplate
  [AbstractValueTypeEnum.ReferenceValue]?: {
    reference?: {
      multiple?: boolean
      allowedMappedTypes?: MappingTypesEnum[]
    }
  }
}

export interface DocumentTypeTranslationsStringTemplateFixedValueTemplate {
  label?: string
  placeholder?: string
}

export interface DocumentTypeTranslationsStringTemplateEntityReferenceValueTemplate {
  label?: string
  placeholder?: string

}

export interface DocumentTypeTranslationsStringTemplate {
  title?: string
  template?: {
    label?: string
    placeholder?: string
    options?: {
      [AbstractValueTypeEnum.FixedValue]?: string
      [AbstractValueTypeEnum.EntityReference]?: string
    }
    [AbstractValueTypeEnum.FixedValue]?: DocumentTypeTranslationsStringTemplateFixedValueTemplate
    [AbstractValueTypeEnum.EntityReference]?: DocumentTypeTranslationsStringTemplateEntityReferenceValueTemplate
  }
  locale?: {
    label?: string
    placeholder?: string
    [AbstractValueTypeEnum.ReferenceValue]?: {
      label?: string
      placeholder?: string
    }
    [AbstractValueTypeEnum.FixedValue]?: {
      label?: string
      placeholder?: string
    }
  }
  renderer?: {
    label?: string
    placeholder?: string
  }
}

export interface DocumentTypeTranslations {
  [AbstractValueTypeEnum.ReferenceValue]?: {
    title: string
    reference: {
      label: string
      placeholder: string
    }
  }
  [AbstractValueTypeEnum.ExpressionValue]?: {
    title: string
    expression: {
      label: string
      placeholder: string
    }
  }
  [AbstractValueTypeEnum.FixedValue]?: {
    title: string
    value: {
      label: string
      placeholder: string
    }
  }
  [AbstractValueTypeEnum.EntityReference]?: {
    title: string
    iri: {
      label: string
      placeholder: string
    }
  }
  [AbstractValueTypeEnum.StringTemplate]?: DocumentTypeTranslationsStringTemplate
}
