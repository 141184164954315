import type { AxiosError, AxiosResponse } from 'axios'
import { InterceptorType } from '../../createApi.ts'
import { ClientError, NetworkError, ServerError } from '../../Error'
import AbortedError from '../../Error/AbortedError.ts'
import type { InterceptorConfig } from '../../createApi.ts'

export function ErrorHandlerResponseInterceptor(): InterceptorConfig<InterceptorType.RESPONSE> {
  async function resolved(response: AxiosResponse): Promise<AxiosResponse> {
    if (response.status >= 400 && response.status < 499)
      throw new ClientError(response.config, response.request, response)

    if (response.status >= 500 && response.status < 599)
      throw new ServerError(response.config, response.request, response)

    return response
  }

  async function rejected(error: AxiosError): Promise<AxiosError> {
    if (!error?.config)
      throw error

    if (error?.code === 'ERR_CANCELED') {
      console.log('Request aborted by user')
      throw new AbortedError()
    }
    if (!error?.response)
      throw new NetworkError()

    if (error.response.status >= 400 && error.response.status < 499)
      throw new ClientError(error.config, error.request, error.response)

    if (error.response.status >= 500 && error.response.status < 599)
      throw new ServerError(error.config, error.request, error.response)

    return error
  }

  return {
    name: 'ErrorHandlerResponseInterceptor',
    type: InterceptorType.RESPONSE,
    priority: -1000,
    rejected,
    resolved,
  }
}
