import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { object, string } from 'yup'
import type { ComputedRef } from 'vue'
import type { CaseFileHydraItem } from '../case_file.interface.ts'

export const reopenObjectSchema = object({
  closeReason: string().nullable().default(null),
})

export function useCaseFileReOpen(onRefresh: () => void, caseFileId?: ComputedRef<string | undefined>) {
  const confirm = useConfirm()
  const { t } = useI18n()
  const { onError } = useServerErrorHandler()
  const toast = useToast()

  function reopenCaseFile() {
    if (!caseFileId?.value) {
      return
    }
    confirm.require({
      header: t('case_file.closeReason.reopen.confirm_title'),
      message: t('case_file.closeReason.reopen.confirm_message'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          await api.patch<CaseFileHydraItem>(`/api/case_files/${caseFileId.value}`, reopenObjectSchema.getDefault())
          toast.add({
            severity: 'success',
            summary: t('case_file.closeReason.reopen.success_title'),
            detail: t('case_file.closeReason.reopen.success_message'),
            life: 3000,
          })
          onRefresh()
        }
        catch (e) {
          onError(e)
          throw e
        }
      },
    })
  }

  return {
    reopenCaseFile,
  }
}
