import type { PayoutHydraCollectionItem, PayoutsStats } from './payouts.interface.ts'

export const usePayoutsStore = createGlobalState(() => {
  const items = ref<PayoutHydraCollectionItem[]>([])
  const loading = ref(false)
  const initialLoading = ref(false)
  const stats = ref<PayoutsStats | null>(null)
  const statsLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    statsLoading.value = false
    items.value = []
    stats.value = null
  }

  return {
    stats,
    reset,
    statsLoading,
    items,
    loading,
    initialLoading,
  }
})
