import type { HydraContext, HydraTimeBased } from '../../../common'
import type { AddressLookupInterface } from '../address'
import type { ProfileInterface } from '../profile'

export enum DebtorType {
  Organization = 1,
  Individual = 2,
}
export const debtorTypes = {
  Individual: DebtorType.Individual,
  Organization: DebtorType.Organization,
}

export interface DebtorHydraItem extends HydraContext, HydraTimeBased, ProfileInterface {
  '@context': 'DebtorHydraItem'
  'type': DebtorType
  'address': AddressLookupInterface
}
export interface DebtorStats {
  debtors: number
  bookmarked: number
}
