export function useLogout() {
  const authStore = useAuthStore()
  const router = useRouter()
  const { config } = useConfigStore()
  const { isPortal } = useInstanceStore()

  async function logout(redirectToLogin = true) {
    if (!config.value)
      throw new Error('Config not set')

    if (redirectToLogin) {
      if (isPortal.value) {
        localStorage.removeItem('selected-brand-type')
      }
      authStore.reset()
      if (import.meta.env.VITE_SKIP_OAUTH === 'true') {
        router.replace('/auth/login').then()
      }
      else {
        window.open(`${config.value.loginUrl}/logout`, '_self')
      }
    }
  }

  return { logout }
}
