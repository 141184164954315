const mode = useColorMode()

export const columnStyle = computed(() => ({
  root: `${mode.value === 'light' && 'surface-0'}`,
  headerTitle: 'text-800',
  filterInput: 'p-inputtext-sm',
}))
export const tableStyle = computed(() => (
  {
    header: `${mode.value === 'light' && 'surface-0'} border-round-top border-top-none`,
    footer: `${mode.value === 'light' && 'surface-0'}`,
    bodyRow: 'row-hover',
  }
))
