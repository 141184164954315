import AuthRoutes from '@features/auth/AuthRoutes.ts'
import MainRoutes from '@src/routes/MainRoutes.ts'

export const routes = [
  {
    name: 'admin',
    path: '/',
    component: () => import('@layouts/DefaultLayout.vue'),
    children: MainRoutes,
    meta: { isRequiredAuth: true },
  },
  {
    name: 'Auth',
    path: '/auth',
    children: AuthRoutes,
    meta: { isRequiredAuth: false },
  },
  {
    name: 'Debug',
    path: '/debug',
    component: () => import('lib/src/components/DebugTools/DebugPage.vue'),
    children: [
      {
        path: 'tools',
        name: 'tools',
        component: () => import('lib/src/components/DebugTools/DebugPage.vue'),
      },
    ],
    meta: { isRequiredAuth: false },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'admin' },
  },
]
