export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_PARK_MANAGER = 'ROLE_PARK_MANAGER',
  ROLE_FRONT_DESK = 'ROLE_FRONT_DESK',
  ROLE_DEPARTMENT_MANAGER = 'ROLE_DEPARTMENT_MANAGER',
  ROLE_TEAM_MANAGER = 'ROLE_TEAM_MANAGER',
  ROLE_USER = 'ROLE_USER',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
}
