import { PermissionResources } from 'lib'
import type { RouteRecordRaw } from 'vue-router'

export const SettingsRoutes: RouteRecordRaw[] = [
  {
    path: 'system',
    name: 'system',
    meta: {
      navbar: {
        defaultCollapsed: true,
        name: 'system',
        hasChildren: true,
      },
    },
    children: [
      {
        path: 'details',
        name: 'details',
        meta: {
          navbar: {
            name: 'details',
            icon: 'pi-user',
          },
          type: 'me',
        },
        component: () =>
          import('lib/src/components/Features/user/edit/UserEdit.vue'),
      },
      {
        path: 'calculation_and_costs',
        name: 'calculation_and_costs',
        meta: {
          navbar: {
            name: 'calculation_and_costs',
            icon: ['far', 'calculator'],
            hasChildren: true,
          },
        },
        children: [
          {
            path: 'interest_rate_groups',
            name: 'interest_rate_groups',
            meta: {
              permissionResources: [PermissionResources.INTEREST_RATE_GROUP],
              navbar: {
                name: 'interest_rate_groups',
                icon: ['far', 'hand-holding-usd'],
              },
            },
            component: () =>
              import('@features/interest_rate_groups/InterestRateGroups.vue'),
          },
          {
            path: 'collection_cost_groups',
            name: 'collection_cost_groups',
            meta: {
              permissionResources: [PermissionResources.COLLECTION_COST_GROUP],
              navbar: {
                name: 'collection_cost_groups',
                icon: 'pi-money-bill',
              },
            },
            component: () =>
              import(
                '@features/collection_cost_groups/CollectionCostGroups.vue'
              ),
          },
        ],
      },
      {
        path: 'terms',
        name: 'terms',
        meta: {
          navbar: {
            name: 'terms',
            icon: 'pi-tags',
            hasChildren: true,
          },
        },
        children: [
          {
            path: 'activity_generic_types',
            name: 'activity_generic_types',
            meta: {
              permissionResources: [PermissionResources.ACTIVITY_GENERIC_TYPE],
              navbar: {
                name: 'activity_generic_types',
                icon: 'pi-tag',
              },
            },
            component: () =>
              import(
                '@features/activity_generic_types/ActivityGenericTypes.vue'
              ),
          },
          {
            path: 'case_file_close_reasons',
            name: 'case_file_close_reasons',
            meta: {
              permissionResources: [PermissionResources.CASE_FILE_CLOSE_REASON],
              navbar: {
                name: 'case_file_close_reasons',
                icon: 'pi-tag',
              },
            },
            component: () =>
              import(
                '@features/case_file_close_reasons/CaseFileCloseReasons.vue'
              ),
          },
          {
            path: 'case_file_statuses',
            name: 'case_file_statuses',
            meta: {
              permissionResources: [PermissionResources.CASE_FILE_STATUS],
              navbar: {
                name: 'case_file_statuses',
                icon: 'pi-tag',
              },
            },
            component: () =>
              import('@features/case_file_statuses/CaseFileStatuses.vue'),
          },
          {
            path: 'media_object_sorts',
            name: 'media_object_sorts',
            meta: {
              permissionResources: [PermissionResources.MEDIA_OBJECT_SORT],
              navbar: {
                name: 'media_object_sorts',
                icon: 'pi-tag',
              },
            },
            component: () =>
              import('@features/media_object_sorts/MediaObjectSorts.vue'),
          },
          {
            path: 'third_party_sorts',
            name: 'third_party_sorts',
            meta: {
              permissionResources: [PermissionResources.THIRD_PARTY_SORT],
              navbar: {
                name: 'third_party_sorts',
                icon: 'pi-tag',
              },
            },
            component: () =>
              import('@features/third_party_sorts/ThirdPartySorts.vue'),
          },
        ],
      },

      {
        path: 'access_control',
        name: 'access_control',
        meta: {
          navbar: {
            name: 'access_control',
            icon: 'pi-shield',
            hasChildren: true,
          },
        },
        children: [
          {
            path: 'users',
            name: 'system-users',
            meta: {
              permissionResources: [PermissionResources.USER],
              navbar: {
                name: 'system-users',
                icon: 'pi-user',
              },
            },
            component: () => import('lib/src/components/Features/user/Users.vue'),
          },
          {
            path: 'users/:userId',
            name: 'user',
            component: () => import('lib/src/components/Features/user/edit/UserEdit.vue'),
          },
          {
            path: 'users/new',
            name: 'user_new',
            meta: {
              permissions: [PermissionAttributes.GLOBAL.OPERATION.USER.CREATE],
            },
            component: () => import('lib/src/components/Features/user/create/UserCreate.vue'),
          },
          {
            path: 'permission-groups',
            name: 'permission-groups',
            meta: {
              permissionResources: [PermissionResources.PERMISSION_TEMPLATE_GROUP],
              navbar: {
                name: 'permission-groups',
                icon: ['far', 'user-shield'],
              },
            },
            component: () =>
              import('@features/permission_template_groups/PermissionTemplateGroups.vue'),
          },
          {
            path: 'permission-groups/:id',
            name: 'permission-group',
            component: () =>
              import(
                '@features/permission_template_groups/permission_template_group/EditPermissionTemplateGroup.vue'
              ),
          },
          {
            path: 'permission-groups/new',
            name: 'permission-group-new',
            meta: {
              permissions: [PermissionAttributes.GLOBAL.OPERATION.PERMISSION_TEMPLATE_GROUP.CREATE],
            },
            component: () =>
              import(
                '@features/permission_template_groups/permission_template_group/CreatePermissionTemplateGroup.vue'
              ),
          },
        ],
      },
      {
        path: 'accountancy',
        name: 'accountancy',
        meta: {
          navbar: {
            name: 'accountancy',
            icon: ['far', 'receipt'],
            hasChildren: true,
          },
        },
        children: [
          {
            path: 'products',
            name: 'products',
            meta: {
              permissionResources: [PermissionResources.PRODUCT],
              navbar: {
                name: 'products',
                icon: ['far', 'box'],
              },
            },
            component: () => import('@features/products/Products.vue'),
          },
          {
            path: 'ledger-accounts',
            name: 'ledger-accounts',
            meta: {
              permissionResources: [PermissionResources.GENERAL_LEDGER],
              navbar: {
                name: 'ledger-accounts',
                icon: 'pi-wallet',
              },
            },
            component: () =>
              import('@features/general_ledgers/GeneralLedgers.vue'),
          },
          {
            path: 'units',
            name: 'units',
            meta: {
              permissionResources: [PermissionResources.UNIT],
              navbar: {
                name: 'units',
                icon: ['far', 'box-archive'],
              },
            },
            component: () => import('@features/units/Units.vue'),
          },
        ],
      },
      {
        path: 'templates',
        name: 'templates',
        meta: {
          navbar: {
            name: 'templates',
            icon: ['far', 'books'],
            hasChildren: true,
          },
        },
        children: [
          {
            path: 'email-templates',
            name: 'email-templates',
            meta: {
              permissionResources: [PermissionResources.EMAIL_TEMPLATE],
              navbar: {
                name: 'email-templates',
                icon: ['far', 'envelopes-bulk'],
              },
            },
            component: () =>
              import('@features/email_templates/EmailTemplates.vue'),
          },
          {
            path: 'email-templates/:id',
            name: 'email-template',
            component: () =>
              import(
                '@features/email_templates/email_template/edit/EmailTemplate.vue'
              ),
          },
          {
            path: 'email-templates/new',
            name: 'email-template-new',
            meta: {
              permissions: [PermissionAttributes.GLOBAL.OPERATION.EMAIL_TEMPLATE.CREATE],
            },
            component: () =>
              import(
                '@features/email_templates/email_template/create/EmailTemplateCreate.vue'
              ),
          },
          {
            path: 'pdf-templates',
            name: 'pdf-templates',
            meta: {
              permissionResources: [PermissionResources.PDF_TEMPLATE],
              navbar: {
                name: 'pdf-templates',
                icon: ['far', 'file-invoice'],
              },
            },
            component: () => import('@features/pdf_templates/PdfTemplates.vue'),
          },
          {
            path: 'pdf-templates/:id',
            name: 'pdf-template',
            component: () =>
              import(
                '@features/pdf_templates/pdf_template/edit/PdfTemplate.vue'
              ),
          },
          {
            path: 'pdf-templates/new',
            name: 'pdf-template-new',
            meta: {
              permissions: [PermissionAttributes.GLOBAL.OPERATION.PDF_TEMPLATE.CREATE],
            },
            component: () =>
              import(
                '@features/pdf_templates/pdf_template/create/PdfTemplateCreate.vue'
              ),
          },
          {
            path: 'text-templates',
            name: 'text-templates',
            meta: {
              permissionResources: [PermissionResources.TEXT_TEMPLATE],
              navbar: {
                name: 'text-templates',
                icon: ['far', 'comments'],
              },
            },
            component: () =>
              import('@features/text_templates/TextTemplates.vue'),
          },
          {
            path: 'text-templates/:id',
            name: 'text-template',
            component: () =>
              import(
                '@features/text_templates/text_template/edit/TextTemplate.vue'
              ),
          },
          {
            path: 'text-templates/new',
            name: 'text-template-new',
            meta: {
              permissions: [PermissionAttributes.GLOBAL.OPERATION.TEXT_TEMPLATE.CREATE],
            },
            component: () =>
              import(
                '@features/text_templates/text_template/create/TextTemplateCreate.vue'
              ),
          },
        ],
      },
      {
        path: 'number_sequences',
        name: 'number_sequences',
        meta: {
          permissionResources: [PermissionResources.NUMBER_SEQUENCE],
          navbar: {
            name: 'number_sequences',
            icon: ['far', 'box-archive'],
          },
        },
        component: () => import('@features/number_sequences/NumberSequences.vue'),
      },
      {
        path: 'task-lists',
        name: 'task-lists',
        meta: {
          permissionResources: [PermissionResources.TASK_LIST],
          navbar: {
            name: 'task-lists',
            icon: 'pi-list',
          },
        },
        component: () => import('@features/task_lists/TaskLists.vue'),
      },
      {
        path: 'case_file_lists',
        name: 'case_file_lists',
        meta: {
          permissionResources: [PermissionResources.CASE_FILE_LIST],
          navbar: {
            name: 'case_file_lists',
            icon: ['far', 'list-timeline'],
          },
        },
        component: () => import('@features/case_file_lists/CaseFileLists.vue'),
      },
      {
        path: 'administrations',
        name: 'administrations',
        meta: {
          permissionResources: [PermissionResources.ADMINISTRATION],
          navbar: {
            name: 'administrations',
            icon: ['far', 'briefcase'],
          },
        },
        component: () =>
          import(
            'lib/src/components/Features/administrations/Administrations.vue'
          ),
      },
      {
        path: 'financial_accounts',
        name: 'financial_accounts',
        meta: {
          permissionResources: [PermissionResources.FINANCIAL_ACCOUNT],
          navbar: {
            name: 'financial_accounts',
            icon: ['far', 'coins'],
          },
        },
        component: () =>
          import(
            'lib/src/components/Features/financial_accounts/FinancialAccounts.vue'
          ),
      },
      {
        path: 'system-settings',
        name: 'system-settings',
        // beforeEnter: (to, from, next) => {
        //   const {hasPermission} = usePermissions()
        //
        //   if (hasPermission(PermissionAttributes.CONTEXT.CLIENT.OPERATION.))
        //     return true;
        //
        //   return true;
        //   return next({
        //     name: 'access_denied',
        //   })
        // },
        meta: {
          permissionResources: [
            PermissionResources.CLIENT_SETTING,
            PermissionResources.INTEREST_RATE_SETTING,
            PermissionResources.COLLECTION_COST_GROUP_SETTING,
          ],
          navbar: {
            name: 'system-settings',
            icon: 'pi-cog',
          },
        },
        component: () =>
          import(
            'lib/src/components/Features/SystemSettings/SystemSettingsPage.vue'
          ),
      },
      {
        path: 'appstore',
        name: 'appstore',
        meta: {
          permissionResources: [
            PermissionResources.APP,
            PermissionResources.APP_SUBSCRIPTION,
          ],
          navbar: {
            name: 'appstore-installed',
            icon: 'pi-shopping-bag',
            todo: true,
            hasChildren: false,
          },
          internal: true,
        },
        component: () => import('lib/src/components/Features/applications/Appstore.vue'),
        children: [
          {
            path: 'apps',
            name: 'appstore-apps',
            component: () => import('lib/src/components/Features/applications/Apps.vue'),
          },
          {
            path: 'installed',
            name: 'appstore-installed',
            component: () => import('lib/src/components/Features/applications/AppSubscriptions.vue'),
          },
          {
            path: ':id',
            name: 'app-subscription',
            component: () => import('lib/src/components/Features/applications/AppSubscription.vue'),
          },
        ],
      },
    ],
  },
]

export default SettingsRoutes
