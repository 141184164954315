import type { TreeNode } from 'primevue/treenode'
import { CorrespondenceTemplateContextEnum } from '../../../../../../composables'
import { CaseFileMapping } from './CaseFile/CaseFileMapped.ts'
import { ClientMapped } from './Client/ClientMapped.ts'
import { IndividualMapped } from './Individual/IndividualMapped.ts'
import { MappingTypesEnum } from './MappingTypesEnum.ts'
import { OrganizationMapped } from './Organization/OrganizationMapped.ts'
import type { MappedEntity, TypedMap } from './mapping.interface.ts'

export function getNodes(
  t: (v: string) => string,
  mappedEntity: MappedEntity,
  {
    allowedMappedTypes,
    includeRoot,
    onlyParentEnums,
  }: {
    allowedMappedTypes?: MappingTypesEnum[]
    includeRoot?: boolean
    onlyParentEnums?: boolean
  } = {},
): TreeNode[] {
  const nodes: TreeNode[] = []

  const processEntity = (
    entity: MappedEntity,
    path: string = '[value]',
  ): TreeNode[] =>
    Object.entries(entity)
      .map(([key, value]) => {
        if (value.type === MappingTypesEnum.ROOT_PARENT && !includeRoot) {
          return null
        }
        const baseNode: TreeNode = {
          key:
            value.type === MappingTypesEnum.ROOT_PARENT
              ? path
              : `${path}.${key}`,
          label: t(value.label),
          selectable:

          onlyParentEnums
            ? ((value.type === MappingTypesEnum.PARENT || value.type === MappingTypesEnum.ROOT_PARENT) && 'enum' in value && !!value.enum)

            : allowedMappedTypes
              ? value.type === MappingTypesEnum.ROOT_PARENT
                ? allowedMappedTypes.includes(value.type)
                || allowedMappedTypes.includes(MappingTypesEnum.PARENT)
                : allowedMappedTypes.includes(value.type)
              : true,
        }

        // Handle nested children for ParentMap
        if (value.type === MappingTypesEnum.PARENT && value.children) {
          baseNode.children = processEntity(value.children, `${path}.${key}`)
        }

        return baseNode
      })
      .filter(v => v !== null) as TreeNode[]

  nodes.push(...processEntity(mappedEntity))

  return nodes
}

export function findNode(
  t: (v: string) => string,
  mappedEntity?: MappedEntity | null,
  path?: string | null,
): TypedMap | null {
  if (!path || !mappedEntity) {
    return null
  }
  console.log('[findNode] path', path)
  // noinspection SuspiciousTypeOfGuard

  if (path === '[value]') {
    if (mappedEntity._) {
      return {
        ...mappedEntity._,
        label: t(mappedEntity._.label),
      }
    }
  }

  if (typeof path !== 'string' || !path.includes('.')) {
    return null
  }
  const pathParts = path.split('.') as string[]
  pathParts.shift()

  // This function will initially get a mappedEntity. In the find loop it can also get a TypedMap if the object is not an TYPE based one so node.type === 'string'.

  const find = (
    node: MappedEntity | TypedMap,
    parts: string[],
    parentLabels: string[],
  ): TypedMap | null => {
    // If for some reason we dont have a currentKey, we return null
    console.log('[findNode] node', node)
    const currentKey = parts.shift()
    if (!currentKey) {
      return null
    }

    let searchNode: TypedMap
    // In the case of a TypedMap, we can directly return the node because there are no children, so we need to search in the parent
    if ('type' in node && typeof node.type === 'string') {
      searchNode = node as TypedMap
    }
    else {
      // If the currentkey exists in the searchNode, we can continue searching but if this is the last part of the path, we return the node
      searchNode = node[currentKey]
      if (parts.length === 0 && searchNode) {
        return {
          ...searchNode,
          label: [...parentLabels, t(searchNode.label)].join(' - '),
        }
      }
    }
    if (!searchNode) {
      return null
    }
    console.log('[findNode] searchNode', searchNode)
    if (searchNode.type === MappingTypesEnum.PARENT) {
      parentLabels.push(t(searchNode.label))
      return find(searchNode.children, parts, parentLabels)
    }
    else {
      return {
        ...searchNode,
        label: [...parentLabels, t(searchNode.label)].join(' - '),
      }
    }
  }
  return find(mappedEntity, pathParts, [])
}

export function getMappingByCorrespondenceTemplateContextEnum(
  entity?: CorrespondenceTemplateContextEnum | null,
) {
  if (!entity)
    return null

  switch (entity) {
    case CorrespondenceTemplateContextEnum.CASE_FILE:
      return CaseFileMapping
    case CorrespondenceTemplateContextEnum.CLIENT:
      return ClientMapped
    case CorrespondenceTemplateContextEnum.INDIVIDUAL:
      return IndividualMapped
    case CorrespondenceTemplateContextEnum.ORGANIZATION:
      return OrganizationMapped
    default:
      return {}
  }
}
