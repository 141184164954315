import { isAxiosError } from 'axios'
import dayjs from 'dayjs'
import cloneDeep from 'lodash-es/cloneDeep'
import type { DataTableSortEvent } from 'primevue/datatable'
import type { TagProps } from 'primevue/tag'
import type { ComputedRef, Ref } from 'vue'
import { FilterMatchMode } from '../../common'
import { PER_PAGE } from './table.interface.ts'
import type { HydraCollection } from '../../common'
import type { ColumnsType, TableLoadOptions } from './table.interface.ts'

export interface DefaultType {
  matchMode:
    | FilterMatchMode.EQUALS
    | FilterMatchMode.EXISTS
    | FilterMatchMode.PERCENTAGE
    | FilterMatchMode.CURRENCY
    | FilterMatchMode.NUMBER
    | FilterMatchMode.DATE_BETWEEN
    | FilterMatchMode.BOOLEAN
    | FilterMatchMode.CONTAINS
    | FilterMatchMode.DATE_AFTER
    | FilterMatchMode.DATE_BEFORE
}

export interface DropdownType<T> {
  matchMode: FilterMatchMode.DROPDOWN | FilterMatchMode.EXISTS_DROPDOWN | FilterMatchMode.EXISTS_TRUE_VALUE_DROPDOWN
  options: Record<string, any>[]
  getSeverity?: (v: {
    option: T
    /**
     * Index of the option
     */
    index: number
  }) => TagProps['severity']
}

export interface MultiDropdownType<T> {
  matchMode: FilterMatchMode.MULTI_DROPDOWN
  // multiSearchKeys?: ()
  options: Record<string, any>[]
  multiSearchKey: (v: any) => string
  getSeverity?: (v: {
    option: T
    /**
     * Index of the option
     */
    index: number
  }) => TagProps['severity']
}

export interface DefaultFilterValue {
  value: any
  searchKey?: string
  disableBrackets?: boolean
  getInitialValue?: (v?: string) => any
  sortKey?: string
}

export type FilterValue<T> = DefaultFilterValue &
  (DropdownType<T> | DefaultType | MultiDropdownType<T>)

export function getFilterParamKey(
  key: string,
  filterMode: Extract<FilterMatchMode, FilterMatchMode.DATE_BETWEEN>,
  isGlobal?: boolean,
  disableBrackets?: boolean,
): string[]
export function getFilterParamKey(
  key: string,
  filterMode: Exclude<FilterMatchMode, FilterMatchMode.DATE_BETWEEN>,
  isGlobal?: boolean,
  disableBrackets?: boolean,
): string
export function getFilterParamKey(
  key: string,
  filterMode: FilterMatchMode,
  isGlobal = false,
  disableBrackets?: boolean,
): string | string[] {
  switch (filterMode) {
    case FilterMatchMode.EXISTS_DROPDOWN:
    case FilterMatchMode.EXISTS:
      return `exists[${key}]`
    case FilterMatchMode.PERCENTAGE:
    case FilterMatchMode.NUMBER:
    case FilterMatchMode.CURRENCY:
    case FilterMatchMode.EQUALS:
    case FilterMatchMode.BOOLEAN:
    case FilterMatchMode.CONTAINS:
    case FilterMatchMode.MULTI_DROPDOWN:
    case FilterMatchMode.DROPDOWN:
      return isGlobal ? `and[or][][${key}]` : `${key}${disableBrackets ? '' : '[]'}`
    case FilterMatchMode.DATE_BETWEEN:
      return [`${key}[after]`, `${key}[before]`]
    case FilterMatchMode.DATE_AFTER:
      return `${key}[after]`
    case FilterMatchMode.DATE_BEFORE:
      return `${key}[before]`
  }

  return `${key}[]`
}

export function addFiltersToParams<ItemInterface>(
  params: URLSearchParams,
  filters?: Partial<Record<keyof ItemInterface, FilterValue<ItemInterface>>> &
  Record<'global', FilterValue<ItemInterface>>,
  globalKeys: (keyof ItemInterface)[] | undefined = [],
  columns?: Ref<ColumnsType<ItemInterface>>,
) {
  if (filters) {
    for (const [key, filter] of Object.entries(filters)) {
      if (columns) {
        const column = columns.value.find(c => c.field === key)
        if (column?.visible === false)
          continue
      }
      if ('value' in filter && filter?.value !== null) {
        let value = filter.value
        if (
          FilterMatchMode.CURRENCY === filter.matchMode
          || FilterMatchMode.PERCENTAGE === filter.matchMode
        ) {
          value = value * 100
        }

        switch (filter.matchMode) {
          case FilterMatchMode.EXISTS_TRUE_VALUE_DROPDOWN:
            params.set(
              `exists[${value}]`,
              'true',
            )
            break
          case FilterMatchMode.EXISTS_DROPDOWN:
          case FilterMatchMode.EXISTS:
            params.set(
              getFilterParamKey(key, FilterMatchMode.EXISTS, undefined),
              `${!!value}`,
            )
            break
          case FilterMatchMode.NUMBER:
          case FilterMatchMode.CURRENCY:
          case FilterMatchMode.EQUALS:
          case FilterMatchMode.BOOLEAN:
          case FilterMatchMode.PERCENTAGE:
          case FilterMatchMode.CONTAINS:
          case FilterMatchMode.DROPDOWN:
            console.log(filter, 'FILTER')
            if (key === 'global') {
              globalKeys.forEach((globalKey) => {
                params.append(
                  getFilterParamKey(
                    globalKey as string,
                    FilterMatchMode.DROPDOWN,
                    true,
                  ),
                  `${value}`,
                )
              })
            }
            else {
              const searchKey = filter.searchKey ?? key
              params.set(`${searchKey}${filter.disableBrackets ? '' : '[]'}`, `${value}`)
            }
            break
          case FilterMatchMode.MULTI_DROPDOWN:
            console.log(filter, 'FILTER')
            if (key === 'global') {
              globalKeys.forEach((globalKey) => {
                params.append(
                  getFilterParamKey(
                    globalKey as string,
                    FilterMatchMode.MULTI_DROPDOWN,
                    true,
                  ),
                  `${value}`,
                )
              })
            }
            else {
              params.set(`${filter.multiSearchKey(value)}${filter.disableBrackets ? '' : '[]'}`, `${value}`)
            }
            break
          case FilterMatchMode.DATE_BETWEEN:
            if (filter.value) {
              const afterDate = dayjs(value[0]).startOf('day')
              if (filter.value[0] && filter.value[1]) {
                const beforeDate = dayjs(value[1]).endOf('day')

                params.append(
                  getFilterParamKey(key, FilterMatchMode.DATE_BETWEEN)[0],
                  afterDate.format(),
                )
                params.append(
                  getFilterParamKey(key, FilterMatchMode.DATE_BETWEEN)[1],
                  beforeDate.format(),
                )
              }
              else if (filter.value[0]) {
                params.append(
                  getFilterParamKey(key, FilterMatchMode.DATE_BETWEEN)[0],
                  afterDate.format(),
                )
              }
            }
            break
          case FilterMatchMode.DATE_AFTER:
            if (filter.value) {
              const afterDate = dayjs(value)
              params.append(
                getFilterParamKey(key, FilterMatchMode.DATE_AFTER),
                afterDate.format(),
              )
            }
            break
          case FilterMatchMode.DATE_BEFORE:
            if (filter.value) {
              const beforeDate = dayjs(value)
              params.append(
                getFilterParamKey(key, FilterMatchMode.DATE_BEFORE),
                beforeDate.format(),
              )
            }
            break
        }
      }
    }
  }
}

export function addSortingToParams<ItemInterface>(
  params: URLSearchParams,
  lazyEvent: DataTableSortEvent | null,
  filters?: Partial<Record<keyof ItemInterface, FilterValue<ItemInterface>>> &
  Record<'global', FilterValue<ItemInterface>>,
  columns?: Ref<ColumnsType<ItemInterface>>,
) {
  if (lazyEvent?.sortField && typeof lazyEvent.sortField === 'string') {
    if (columns) {
      const column = columns.value.find(c => c.field === lazyEvent.sortField)
      if (column?.visible === false)
        return
    }
    let sortKey = lazyEvent.sortField
    if (filters) {
      console.log('=>(table.helpers.ts:202) filters', filters)
      const newSortKey
                = filters[
                  lazyEvent.sortField as keyof Record<
                    keyof ItemInterface,
                    FilterValue<ItemInterface>
                  >
                ]?.sortKey
      if (newSortKey)
        sortKey = newSortKey
    }
    params.set(`order[${sortKey}]`, lazyEvent.sortOrder === 1 ? 'ASC' : 'DESC')
  }
}

export function useTableHelpers<ItemInterface>({
  loading,
  initialLoading,
  items,
  page,
  preventExecute = false,
  runOnChange = true,
  runOnStart = true,
  onError,
  defaultSort,
  hasNext,
  onFullReset,
  showFilters = ref(true),
  method = 'get',
  columns,
  disable,
  hasPerms,
  body,
  showSorting = ref(true),
  endpoint,
  defaultParams,
  tableCallError,
  onFetch,
  globalKeys = [],
  filters,
}: {
  items: Ref<ItemInterface[]>
  loading: Ref<boolean>
  runOnStart?: boolean
  preventExecute?: boolean
  method?: 'get' | 'post'
  columns?: Ref<ColumnsType<ItemInterface>>
  runOnChange?: boolean
  tableCallError: Ref<'access_denied' | 'not_found' | null>
  hasPerms: ComputedRef<boolean>
  disable: ComputedRef<boolean>
  hasNext: Ref<number>
  body?: Ref<Record<string, any>>
  defaultParams?: Ref<URLSearchParams>
  defaultSort?: Pick<DataTableSortEvent, 'sortOrder' | 'sortField'>
  showFilters?: Ref<boolean>
  showSorting?: Ref<boolean>
  initialLoading: Ref<boolean>
  page: Ref<number>
  endpoint: ComputedRef<string> | string
  onError: (
    error: any,
    lazyEvent: Ref<DataTableSortEvent | null>,
    filters?: Ref<
      Partial<Record<keyof ItemInterface, FilterValue<ItemInterface>>> &
      Record<'global', FilterValue<ItemInterface>>
    >,
  ) => void
  globalKeys?: (keyof ItemInterface)[]
  onFullReset?: () => void
  onFetch: (
    data: HydraCollection<ItemInterface>,
    options: TableLoadOptions,
  ) => Promise<void>
  filters?: Ref<
    Partial<Record<keyof ItemInterface, FilterValue<ItemInterface>>> &
    Record<'global', FilterValue<ItemInterface>>
  >
}): {
    loadData: (options?: TableLoadOptions) => Promise<void>
    lazyEvent: Ref<DataTableSortEvent | null>
    onSort: (event: DataTableSortEvent) => void
  } {
  const abortControllerRef = ref<AbortController | null>(null)
  const lazyEvent = ref<DataTableSortEvent | null>(
    defaultSort && filters?.value
      ? ({
          ...cloneDeep(defaultSort),
          filters: filters?.value,
        } as unknown as DataTableSortEvent)
      : null,
  )

  async function loadData(
    options: TableLoadOptions = {
      reset: false,
      fullReset: false,
    },
  ) {
    console.log('=>(table.helpers.ts:340) options', disable.value)
    if (disable.value || loading.value) {
      return
    }
    if (!hasPerms.value) {
      return
    }
    try {
      loading.value = true
      // console.log('=>(table.helpers.ts:340) hasPerms.value', hasPerms.value)

      if (options.reset) {
        page.value = 1
        initialLoading.value = true
      }
      if (abortControllerRef.value) {
        abortControllerRef.value.abort()
      }
      abortControllerRef.value = new AbortController()
      if (options.fullReset) {
        onFullReset?.()
      }
      if (options.state && options.state.loading)
        options.state.loading()
      const params = new URLSearchParams(defaultParams?.value || '')
      params.set('perPage', `${PER_PAGE}`)
      params.set('page', `${page.value}`)
      if (showFilters && filters?.value && showFilters.value) {
        addFiltersToParams<ItemInterface>(
          params,
          filters.value,
          globalKeys,
          columns,
        )
      }

      if (showSorting && showSorting.value && filters && filters.value) {
        addSortingToParams<ItemInterface>(
          params,
          lazyEvent.value,
          filters.value,
          columns,
        )
      }

      const url = toValue(endpoint)
      if (!url) {
        throw new Error('Endpoint is not defined')
      }

      if (!preventExecute) {
        let responseData: HydraCollection<ItemInterface>
        if (method === 'get') {
          const { data: res } = await api
            .get<HydraCollection<ItemInterface>>(url, { params, signal: abortControllerRef.value?.signal })
          responseData = res
        }
        else {
          const { data: res } = await api
            .post<HydraCollection<ItemInterface>>(url, body?.value ?? {}, { params, signal: abortControllerRef.value?.signal })
          responseData = res
        }

        await onFetch(responseData, options)

        if (responseData['hydra:view']['hydra:next']) {
          const next = new URL(
            responseData['hydra:view']['hydra:next'],
            'https://api.any.com',
          )
          hasNext.value = Number(next.searchParams.get('page'))
          options.state?.loaded()
        }
        else {
          hasNext.value = 0
          options.state?.complete()
        }
      }
      else {
        options.state?.complete()
      }
      tableCallError.value = null
    }
    catch (error: any) {
      if (error.name !== 'AbortedError') {
        if (isAxiosError(error)) {
          if (error.response?.status === 404) {
            items.value = []
            tableCallError.value = 'not_found'
          }
          else if (error.response?.status === 403) {
            items.value = []
            tableCallError.value = 'access_denied'
          }
          else {
            tableCallError.value = null
          }
        }
        else {
          tableCallError.value = null
        }

        console.error(error)
        if (options.state && options.state.error)
          options.state.error()
        onError(error, lazyEvent, filters)
      }
      else {
        console.log('Aborted')
        options.state?.complete()
      }
    }
    finally {
      initialLoading.value = false
      loading.value = false
    }
  }

  if (runOnStart) {
    loadData({
      reset: true,
    }).then()
  }

  function onSort(event: DataTableSortEvent) {
    lazyEvent.value = event
  }

  watch(
    [
      () => columns?.value,
      () => showFilters && showFilters.value,
      () => showSorting && showSorting.value,
      () => lazyEvent.value,
      () => filters?.value,
      () => defaultParams?.value,
      () => body?.value,
      () => hasPerms.value,
    ],
    () => {
      if (runOnChange) {
        loadData({ reset: true }).then()
      }
    },
    {
      deep: true,
    },
  )
  return {
    onSort,
    lazyEvent,
    loadData,
  }
}
