import { type InferType, lazy, mixed, object, ValidationError } from 'yup'
import {
  automationCreateEmailQueueJobActionObjectSchema,
} from './CreateEmailQueueJobAction/CreateEmailQueueJobAction.interface.ts'
import {
  automationCreatePrintQueueJobActionObjectSchema,
} from './CreatePrintQueueJobAction/CreatePrintQueueJobAction.interface.ts'
import {
  automationCreateSmsQueueJobActionObjectSchema,
} from './CreateSmsQueueJobAction/CreateSmsQueueJobAction.interface.ts'
import { automationCreateTaskActionObjectSchema } from './CreateTaskAction/CreateTaskAction.interface.ts'
import { automationUpdateCaseFileActionObjectSchema } from './UpdateCaseFileAction/UpdateCaseFileAction.interface.ts'

export enum AutomationActionTypesEnum {
  CreateEmailQueueJobAction = 'Automation.Action.CreateEmailQueueJob',
  CreatePrintQueueJobAction = 'Automation.Action.CreatePrintQueueJob',
  CreateSmsQueueJobAction = 'Automation.Action.CreateSmsQueueJob',
  CreateTaskAction = 'Automation.Action.CreateTask',
  UpdateCaseFileAction = 'Automation.Action.UpdateCaseFile',
}

export function automationActionObjectSchema(t: (v: string) => string) {
  return object({
    action: lazy((value) => {
      console.log('[DEFAULT_VALUE]:', value)
      switch (value?.['#type']) {
        case AutomationActionTypesEnum.CreateEmailQueueJobAction:
          console.log('CreateEmailQueueJobAction')
          return automationCreateEmailQueueJobActionObjectSchema(t)
        case AutomationActionTypesEnum.CreateSmsQueueJobAction:
          return automationCreateSmsQueueJobActionObjectSchema(t)
        case AutomationActionTypesEnum.CreatePrintQueueJobAction:
          return automationCreatePrintQueueJobActionObjectSchema(t)
        case AutomationActionTypesEnum.CreateTaskAction:
          return automationCreateTaskActionObjectSchema(t)
        case AutomationActionTypesEnum.UpdateCaseFileAction:
          return automationUpdateCaseFileActionObjectSchema(t)
        default:
          console.log('default')
          // Define a schema that will always fail validation for the default case
          return object({
            '#type': mixed()
              .oneOf(
                Object.values(AutomationActionTypesEnum).filter(v =>
                  v.includes('.'),
                ),
              )
              .required(t('common.forms.required')),
          }).test('invalid-actionType', 'Invalid actionType', (_) => {
            return new ValidationError('Invalid action type')
          })
      }
    }),
  })
}

export function getAutomationActionObjectSchemaDefaultValue(
  t: ReturnType<typeof useI18n>['t'],
  v?: AutomationActionTypesEnum | null,
) {
  if (!v)
    return null
  switch (v) {
    case AutomationActionTypesEnum.CreateEmailQueueJobAction:
      return automationCreateEmailQueueJobActionObjectSchema(t).getDefault()
    case AutomationActionTypesEnum.CreateSmsQueueJobAction:
      return automationCreateSmsQueueJobActionObjectSchema(t).getDefault()
    case AutomationActionTypesEnum.CreatePrintQueueJobAction:
      return automationCreatePrintQueueJobActionObjectSchema(t).getDefault()
    case AutomationActionTypesEnum.CreateTaskAction:
      return automationCreateTaskActionObjectSchema(t).getDefault()
    case AutomationActionTypesEnum.UpdateCaseFileAction:
      return automationUpdateCaseFileActionObjectSchema(t).getDefault()
    default:
      return null
  }
}

export type AutomationActionObjectSchema = InferType<
  ReturnType<typeof automationActionObjectSchema>
>
