// store.js
import { createGlobalState } from '@vueuse/core'
import { ref } from 'vue'

export const useNavbar = createGlobalState(
  () => {
    const isDesktop = useMediaQuery('(min-width: 768px)')
    const show = ref(isDesktop.value)
    watch(() => isDesktop.value, (v) => {
      if (!v) {
        show.value = false
      }
    })
    const navbarRef = ref(null)

    const showNavbar = () => {
      show.value = true
    }
    const hideNavbar = () => {
      show.value = false
    }

    return { show, showNavbar, hideNavbar, navbarRef, isDesktop }
  },
)
