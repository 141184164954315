import type { TreeNode } from 'primevue/treenode'

export function findDeepestNode(nodes: TreeNode[], key: string): TreeNode | undefined {
  for (const node of nodes) {
    if (node.key === key)
      return node

    if (node.children) {
      const found = findDeepestNode(node.children, key)
      if (found)
        return found
    }
  }
}
