import type { UserPermissionHydraCollectionItem } from './permissions.interface.ts'

export function getPermissionFieldKey(data: UserPermissionHydraCollectionItem) {
  const keys: (keyof Pick<UserPermissionHydraCollectionItem, 'client' | 'adminstration' | 'organization' | 'individual' | 'thirdParty' | 'userGroup'>)[] = [
    'client',
    'organization',
    'individual',
    'thirdParty',
    'adminstration',
    'userGroup',
  ]

  return keys.filter(key => data[key] !== undefined)
}
