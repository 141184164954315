import { lazy, mixed, object } from 'yup'
import type { InferType } from 'yup'

import { AutomationConditionTypesEnum } from '../automation_condition_edit.interface.ts'
import { automationValueObjectSchema } from '../Value/condition_value.interface.ts'

export enum BooleanOperatorEnum {
  EQUALS = 0,
  NOT_EQUALS = 1,
  IS_TRUE = 24,
  IS_FALSE = 25,
}

export function booleanOperatorEnumOptions(t: (key: string) => string) {
  return Object.values(BooleanOperatorEnum)
    .filter(v => typeof v === 'number')
    .map(value => ({
      value,
      label: t(`automations.condition.operator.options.${value}`),
    }))
}

export function automationBooleanConditionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    'leftValue': automationValueObjectSchema(t),
    'operator': mixed()
      .oneOf(
        Object.values(BooleanOperatorEnum).filter(v => typeof v === 'number'),
        t('common.forms.enum'),
      )
      .required(t('common.forms.required')),
    'rightValue': lazy((_, { parent }) => {
      if (
        parent?.operator === BooleanOperatorEnum.IS_TRUE
        || parent?.operator === BooleanOperatorEnum.IS_FALSE
      ) {
        return object({})
      }
      else {
        return automationValueObjectSchema(t)
      }
    }),
    '#type': mixed()
      .oneOf([AutomationConditionTypesEnum.BooleanCondition])
      .required(t('common.forms.required'))
      .default(AutomationConditionTypesEnum.BooleanCondition),
  })
}

export type AutomationBooleanConditionObjectSchema = InferType<
  ReturnType<typeof automationBooleanConditionObjectSchema>
>
