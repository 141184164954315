import type { ThirdPartyHydraCollectionitem } from './third_parties.interface.ts'

export const useThirdPartiesStore = createGlobalState(() => {
  const items = ref<ThirdPartyHydraCollectionitem[]>([])
  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  return {
    reset,
    items,
    loading,
    initialLoading,
  }
})
