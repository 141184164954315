import accounting from './accounting.json'
import activities from './activities.json'
import address from './address.json'
import administrations from './administrations.json'
import applications from './applications.json'
import automations from './automations.json'
import bookmarks from './bookmarks.json'
import case_file from './case_file.json'
import clients from './clients.json'
import common from './common.json'
import countries from './countries.json'
import dashboard from './dashboard.json'
import debtors from './debtors.json'
import declarations from './declarations.json'
import exports from './exports.json'
import financial_accounts from './financial_accounts.json'
import general_ledger from './general_ledger.json'
import individual from './individual.json'
import invoices from './invoices.json'
import locales from './locales.json'
import media from './media.json'
import media_object_sorts from './media_object_sorts.json'
import media_objects from './media_objects.json'
import organization from './organization.json'
import payouts from './payouts.json'
import permissions from './permissions.json'
import product from './product.json'
import profile from './profile.json'
import queue_jobs from './queue_jobs.json'
import settings from './settings.json'
import subscriptions from './subscriptions.json'
import tasks from './tasks.json'
import third_party from './third_party.json'
import users from './users.json'
import violations from './violations.json'
import workflows from './workflows.json'

export const nl = {
  activities,
  automations,
  media_object_sorts,
  media_objects,
  permissions,
  violations,
  financial_accounts,
  subscriptions,
  accounting,
  product,
  individual,
  address,
  bookmarks,
  organization,
  administrations,
  case_file,
  media,
  debtors,
  exports,
  profile,
  countries,
  locales,
  applications,
  clients,
  workflows,
  common,
  declarations,
  invoices,
  queue_jobs,
  payouts,
  settings,
  tasks,
  users,
  third_party,
  general_ledger,
  dashboard,
}
