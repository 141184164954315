import { mixed, object } from 'yup'
import type { InferType } from 'yup'

import { AutomationConditionTypesEnum } from '../automation_condition_edit.interface.ts'
import { automationValueObjectSchema } from '../Value/condition_value.interface.ts'

export enum NumericOperatorEnum {
  EQUALS = 0,
  NOT_EQUALS = 1,
  GREATER_THAN = 20,
  GREATER_THAN_OR_EQUALS = 21,
  LESS_THAN = 22,
  LESS_THAN_OR_EQUALS = 23,
}

export function numericOperatorEnumOptions(t: (key: string) => string) {
  return Object.values(NumericOperatorEnum).filter(v => typeof v === 'number').map(value => ({
    value,
    label: t(`automations.condition.operator.options.${value}`),
  }))
}

export function automationNumericConditionObjectSchema(t: (v: string) => string) {
  return object({
    'leftValue': automationValueObjectSchema(t),
    'operator': mixed()
      .oneOf(
        Object.values(NumericOperatorEnum).filter(v => typeof v === 'number'),
        t('common.forms.enum'),
      )
      .required(t('common.forms.required')),
    'rightValue': automationValueObjectSchema(t),
    '#type': mixed()
      .oneOf([AutomationConditionTypesEnum.NumericCondition])
      .required(t('common.forms.required'))
      .default(AutomationConditionTypesEnum.NumericCondition),
  })
}

export type AutomationNumericConditionObjectSchema = InferType<
  ReturnType<typeof automationNumericConditionObjectSchema>
>
