import type { RouteRecordRaw } from 'vue-router'

const AuthRoutes: RouteRecordRaw[] = [
  {
    path: 'login',
    name: 'login',
    component: () => import('lib/src/components/Features/auth/Login/Login.vue'),
  },
  {
    path: 'callback',
    name: 'callback',
    component: () => import('lib/src/components/Features/auth/Callback/Callback.vue'),
  },
  {
    path: 'error',
    name: 'error',
    component: () => import('lib/src/components/Features/auth/Error.vue'),
  },
]
export default AuthRoutes
