import { mixed, object } from 'yup'
import type { InferType } from 'yup'
import { AbstractValueTypeEnum, stringTemplateObjectSchema } from '../../../../../../../composables'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreatePrintQueueJobTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  locale: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  automatic: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batch: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batchIdentifier: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
}

export function automationCreatePrintQueueJobActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.CreatePrintQueueJobAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.CreatePrintQueueJobAction),
    'baseContext': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.baseContext,
    }),
    'body': stringTemplateObjectSchema(t),
    'locale': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.locale,
    }),
    'automatic': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.automatic,
    }),
    'batch': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.batch,
    }),
    'batchIdentifier': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.batchIdentifier,
    }),
  })
}

export type AutomationCreatePrintQueueJobActionObjectSchema = InferType<
  ReturnType<typeof automationCreatePrintQueueJobActionObjectSchema>
>
