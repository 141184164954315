import app from '@core/configApp.ts'
import primeNL from '@core/plugins/prime-nl.json'
import BadgeDirective from 'primevue/badgedirective'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'

app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

app.directive('badge', BadgeDirective)
app.use(PrimeVue, {
  ripple: true,
  locale: primeNL,
})
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.use(ToastService)
app.use(ConfirmationService)
