import { mixed, object } from 'yup'
import type { InferType } from 'yup'
import {
  AbstractValueTypeEnum,
} from '../../../../../../../composables/index.ts'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreateEmailQueueJobTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  caseFile: [
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  status: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  closeReason: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  collectionCostGroup: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  sort: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  administration: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
}

export function automationUpdateCaseFileActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.UpdateCaseFileAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.UpdateCaseFileAction),
    'caseFile': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.caseFile,
    }),
    'status': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.status,
      required: false,
    }),
    'closeReason': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.closeReason,
      required: false,
    }),
    'collectionCostGroup': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.collectionCostGroup,
      required: false,
    }),
    'sort': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.sort,
      required: false,
    }),
    'administration': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.administration,
      required: false,
    }),
  }).test({
    name: 'atleast_one_set_and_sort_status_dependency',
    test: (value, ctx) => {
      if ((typeof value.caseFile) === 'object' && Object.keys(value.caseFile).length === 0) {
        return true
      }
      console.log('[UpdateCaseFileAction.interface.ts] Resolved caseFile:', ctx.resolve(value.caseFile))
      console.log('[UpdateCaseFileAction.interface.ts] Resolved status:', ctx.resolve(value.status))
      console.log('[UpdateCaseFileAction.interface.ts] Value:', value)
      console.log('[UpdateCaseFileAction.interface.ts] Context:', ctx)

      const statusValid = typeof value.status === 'object' ? Object.keys(value.status).length > 0 : !!value.status
      const closeReasonValid = typeof value.closeReason === 'object' ? Object.keys(value.closeReason).length > 0 : !!value.closeReason
      const collectionCostGroupValid = typeof value.collectionCostGroup === 'object' ? Object.keys(value.collectionCostGroup).length > 0 : !!value.collectionCostGroup
      const sortValid = typeof value.sort === 'object' ? Object.keys(value.sort).length > 0 : !!value.sort
      const administrationValid = typeof value.administration === 'object' ? Object.keys(value.administration).length > 0 : !!value.administration

      console.log('[UpdateCaseFileAction.interface.ts] Status valid:', statusValid)
      console.log('[UpdateCaseFileAction.interface.ts] Close reason valid:', closeReasonValid)
      console.log('[UpdateCaseFileAction.interface.ts] Collection cost group valid:', collectionCostGroupValid)
      console.log('[UpdateCaseFileAction.interface.ts] Sort valid:', sortValid)
      console.log('[UpdateCaseFileAction.interface.ts] Administration valid:', administrationValid)

      if (!statusValid && !closeReasonValid && !collectionCostGroupValid && !sortValid && !administrationValid) {
        return ctx.createError({
          path: 'action',
          message: t('automations.action.updateCaseFile.atleast_one_set'),
        })
      }

      if (sortValid && !statusValid) {
        return ctx.createError({
          path: 'action.status',
          message: t('automations.action.updateCaseFile.sort_requires_status'),
        })
      }

      return true
    },
  })
}

export type AutomationUpdateCaseFileActionObjectSchema = InferType<
  ReturnType<typeof automationUpdateCaseFileActionObjectSchema>
>
