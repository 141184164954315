import { setLocale } from 'yup'

export function setYupLocales(t: (v: string, args?: any) => string) {
  setLocale({
    mixed: {
      default: t('common.forms.invalid'),
      required: t('common.forms.required'),
      notNull: t('common.forms.required'),
      oneOf: t('common.forms.required'),
      notOneOf: t('common.forms.required'),
      notType: t('common.forms.required'),
      // Add more default error messages for mixed type validations
    },
    string: {
      email: t('common.forms.email'),

      // Add more default error messages for string type validations
    },
    array: {
      min: min => t('common.forms.required', { min }),
      max: max => t('common.forms.required', { max }),
    },
    number: {
      min: min => t('common.forms.min', { min }),
      max: max => t('common.forms.max', { max }),
      positive: t('common.forms.positive'),
      // Add more default error messages for number type validations
    },
  })
}
