import type { RouteRecordRaw } from 'vue-router'

export const DashboardRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'dashboard',
    component: () => import('@features/dashboard/Dashboard.vue'),
  },
]

export default DashboardRoutes
