import type { CalendarProps } from 'primevue/calendar'
import type { DropdownProps } from 'primevue/dropdown'
import type { MultiSelectProps } from 'primevue/multiselect'
import type { LazyDropdownProps } from '../Dropdown/LazyDropdown.ts'

export enum BulkActionEnum {
  DROPDOWN = 'dropdown',
  MULTI_SELECT = 'multi_select',
  CUSTOM_COMPONENT = 'custom_component',
  LAZY_DROPDOWN = 'lazy_dropdown',
  CALENDAR = 'calendar',
  BOOLEAN = 'boolean',
}

export interface BaseAction<Option extends Record<string, any>> {
  icon: string[]
  hasPerms: boolean
  basedOn?: keyof Option
  clearFieldsOnSelect?: (keyof Option)[]
  basedOnMessage?: string
  disabledBy?: (keyof Option)[]
  disabledByMessage?: string
  field: keyof Option
  tooltip: string
}

export type BulkActionDropdown<Option extends Record<string, any>> =
  BaseAction<Option> &
  Omit<DropdownProps, 'options'> & {
    type: BulkActionEnum.DROPDOWN
    allowNull?: boolean
    options: any[] | ((options: Record<string, any>) => any[])
  }

export type BulkActionMultiSelect<Option extends Record<string, any>> =
  BaseAction<Option> &
  Omit<MultiSelectProps, 'options'> & {
    type: BulkActionEnum.MULTI_SELECT
    options: any[] | ((options: Record<string, any>) => any[])
  }

export type BulkActionBoolean<Option extends Record<string, any>> =
  BaseAction<Option> &
  {
    type: BulkActionEnum.BOOLEAN
  }

export type BulkActionCalendar<Option extends Record<string, any>> =
  BaseAction<Option> &
  Omit<CalendarProps, 'icon'> & {
    type: BulkActionEnum.CALENDAR
  }

export type BulkActionLazyDropdown<Option extends Record<string, any>> =
  BaseAction<Option> &
  Omit<LazyDropdownProps, 'inputUnstyled' | 'endpoint' | 'originalValue' | 'onError' | 'modelValue'> & {
    type: BulkActionEnum.LAZY_DROPDOWN
    endpoint: string | ((options: Record<string, any>) => string)
  }

export type BulkActionCustomComponent<Option extends Record<string, any>> =
  BaseAction<Option> & {
    type: BulkActionEnum.CUSTOM_COMPONENT
    component: any
    getValue?: (value: any) => Promise<string>
  }

export type BulkAction<MainType extends Record<string, any>> =
  | BulkActionDropdown<MainType>
  | BulkActionLazyDropdown<MainType>
  | BulkActionCalendar<MainType>
  | BulkActionCustomComponent<MainType>
  | BulkActionBoolean<MainType>
  | BulkActionMultiSelect<MainType>

export const BulkClearValue = 'clear-item'
