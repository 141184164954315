import { array, mixed, object } from 'yup'
import type { InferType } from 'yup'
import {
  AbstractValueTypeEnum,
  type StringTemplateObjectSchema,
  stringTemplateObjectSchema,
} from '../../../../../../../composables'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreateEmailQueueJobTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  recipients: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.StringTemplate,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  carbonCopies: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.StringTemplate,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  blindCarbonCopies: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.StringTemplate,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  parts: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  baseContext: [AbstractValueTypeEnum.ReferenceValue],
  locale: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  automatic: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batch: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batchIdentifier: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
}

export function automationCreateEmailQueueJobActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.CreateEmailQueueJobAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.CreateEmailQueueJobAction),
    'recipients': array()
      .of(
        automationValueObjectSchema(t, {
          allowedTypes: allowedCreateEmailQueueJobTypesMap.recipients,
        }),
      )
      .min(1)
      .default([]),
    'carbonCopies': array().of(automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.carbonCopies,
    })).default([]),
    'blindCarbonCopies': array().of(automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.blindCarbonCopies,
    })).default([]),
    'parts': array().of(automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.parts,
    })).default([]),
    'embeddedParts': array().of(embeddedPartDocumentSchema(t)).default([]),
    'baseContext': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.baseContext,
    }),
    'body': stringTemplateObjectSchema(t),
    'replyToOverride': array().of(mixed().required()).default([]).when('body', ([body], schema) => {
      const bodySchema = body as StringTemplateObjectSchema
      if (bodySchema && (bodySchema.template as StringTemplateObjectSchema)['#type'] === AbstractValueTypeEnum.FixedValue) {
        return schema.required().min(1).default([])
      }
      return schema.optional().nullable().default([])
    }),
    'senderOverride': mixed().when('body', ([body], schema) => {
      const bodySchema = body as StringTemplateObjectSchema
      if (bodySchema && (bodySchema.template as StringTemplateObjectSchema)['#type'] === AbstractValueTypeEnum.FixedValue) {
        return schema.required()
      }
      return schema.optional().nullable().default(null)
    }),
    'subjectTemplateOverride': mixed().when('body', ([body], schema) => {
      const bodySchema = body as StringTemplateObjectSchema
      if (bodySchema && (bodySchema.template as StringTemplateObjectSchema)['#type'] === AbstractValueTypeEnum.FixedValue) {
        return schema.required()
      }
      return schema.optional().nullable().default(null)
    }),
    'automatic': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.automatic,
    }),
    'batch': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.batch,
    }),
    'batchIdentifier': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateEmailQueueJobTypesMap.batchIdentifier,
      required: false,
    }),
  })
}

export type AutomationCreateEmailQueueJobActionObjectSchema = InferType<
  ReturnType<typeof automationCreateEmailQueueJobActionObjectSchema>
>
