import { StorageSerializers } from '@vueuse/core'
import { computed, ref } from 'vue'

export interface AppConfig {
  apiUrl: string
  loginUrl: string
  loginRedirect: string
  loginClientId: string
  loginAuthorizeUrl: string
  loginTokenUrl: string
  mercureUrl: string
  userContentUrl: string
}

function createConfigStore() {
  const initialConfig = ref<AppConfig | null>(null)
  const overrideConfig = useStorage<AppConfig | null>('overrideConfig', null, localStorage, { serializer: StorageSerializers.object })
  const debugMode = useStorage<boolean>('debugMode', false, sessionStorage, { serializer: StorageSerializers.boolean })
  const disableMercure = useStorage<boolean>('disableMercure', !!import.meta.env.DEV, sessionStorage, { serializer: StorageSerializers.boolean })
  const statsEnabled = ref<boolean>(false)
  function setInitialConfig(newConfig: AppConfig) {
    console.log('setInitialConfig', newConfig)
    initialConfig.value = newConfig
  }

  function setOverrideConfig(newConfig: AppConfig) {
    overrideConfig.value = newConfig
  }

  function reset() {
    overrideConfig.value = null
    disableMercure.value = false
  }

  const config = computed<AppConfig | null>(() => {
    if (overrideConfig.value)
      return overrideConfig.value

    return initialConfig.value
  })

  return {
    debugMode,
    config,
    statsEnabled,
    disableMercure,
    overrideConfig,
    setOverrideConfig,
    setInitialConfig,
    reset,
  }
}

type ConfigStoreInterface = ReturnType<typeof createConfigStore>
let backwardsCompatibleInstance: null | ConfigStoreInterface = null
export function useConfigStore(): ConfigStoreInterface {
  if (backwardsCompatibleInstance === null)
    backwardsCompatibleInstance = createConfigStore()

  return backwardsCompatibleInstance
}
export default useConfigStore
