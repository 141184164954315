import { StorageSerializers, useStorage } from '@vueuse/core'
import type { NumberSequenceHydraCollectionItem } from './number_sequences.interface.ts'

export const useNumberSequencesStore = createGlobalState(() => {
  const number_sequences = useStorage<Record<string, NumberSequenceHydraCollectionItem> | null>('number_sequences', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<NumberSequenceHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    number_sequences.value = null
  }

  const { hasPermission } = usePermissions()

  const allowReadNumberSequences = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.UNIT
        .READ_COLLECTION,
    ),
  )
  async function setNumberSequences(): Promise<void> {
    if (!allowReadNumberSequences.value) {
      number_sequences.value = null
      return
    }
    await getAll<NumberSequenceHydraCollectionItem>({ url: '/api/number_sequences' }).then((data: NumberSequenceHydraCollectionItem[]) => {
      number_sequences.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, NumberSequenceHydraCollectionItem>)
      return data
    })
  }

  const number_sequencesOptions = computed(() => {
    return number_sequences.value
      ? Object.values(number_sequences.value).map(numberSequence => ({
        label: `${numberSequence.name} (${numberSequence.prefix ?? ''}${numberSequence.increment})`,
        value: numberSequence['@id'],
      }))
      : []
  })

  const upsertNumberSequence = async (numberSequence: NumberSequenceHydraCollectionItem) => {
    if (!number_sequences.value) {
      return
    }
    number_sequences.value[numberSequence['@id']] = numberSequence
  }

  const removeAdministration = async (iri: string) => {
    if (!number_sequences.value) {
      return
    }
    if (iri in number_sequences.value) {
      delete number_sequences.value[iri]
    }
  }

  return {
    number_sequencesOptions,
    allowReadNumberSequences,
    setNumberSequences,
    removeAdministration,
    reset,
    upsertNumberSequence,
    items,
    number_sequences,
    loading,
    initialLoading,
  }
})
