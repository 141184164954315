import type { Ref } from 'vue'

const [useProvidePermissionEditorOptions, usePermissionEditorOptionsRaw]
  = createInjectionState(
    ({
      enabledKeys = ref<Record<string, string[]>>({}),
      hideKeysWithoutEnabledKeys = ref<boolean>(false),
      mode = ref<'asteriks' | 'default'>('default'),
    }: {
      enabledKeys?: Ref<Record<string, string[]>>
      hideKeysWithoutEnabledKeys?: Ref<boolean>
      mode?: Ref<'asteriks' | 'default'>
    }) => {
      return {
        enabledKeys,
        hideKeysWithoutEnabledKeys,
        mode,
      }
    },
  )
export { useProvidePermissionEditorOptions }

// If you want to hide `usePermissionsTableHelper` and wrap it in default value logic or throw error logic, please don't export `usePermissionsTableHelper`
export function usePermissionEditorOptions() {
  const permissionsStateRaw = usePermissionEditorOptionsRaw()
  if (permissionsStateRaw == null) {
    throw new Error(
      'Please call `useProvidePermissionEditorOptions` on the appropriate parent component',
    )
  }
  return permissionsStateRaw
}
