import { EntityFields } from '../entities/entities.ts'
import type { ValueTypeDocumentEnum } from '../automations.interface.ts'

export enum FunctionNames {
  MakeDateWithInterval = 'make_date_with_interval',
}

export enum AutomationConditionMode {
  ANY = 0,
  ALL = 1,
}

export interface LeftHand {
  '#context': ValueTypeDocumentEnum.GET_VALUE_DOCUMENT
  'value': string
}

export interface RightHandValue {
  '#context': ValueTypeDocumentEnum
  'value': string | number
}

export enum FunctionArgumentType {
  Period = 'period',
}

export interface FunctionArgument {
  '#context': FunctionArgumentType
  'defaultValue': any
}

export interface FunctionInfo {
  '#context': 'FunctionDocument'
  'name': FunctionNames
  'arguments': string[]
}

export function automationConditionModeOptions(t: (v: string) => string): {
  label: string
  value: AutomationConditionMode
}[] {
  return Object.entries(AutomationConditionMode)
    .filter(([_, value]) => typeof value === 'number')
    .map(([key, value]) => ({
      label: t(`automations.conditionMode.options.${value}`),
      value:
        AutomationConditionMode[key as keyof typeof AutomationConditionMode],
    }))
}

export type RightHand = RightHandValue | FunctionInfo

export interface AutomationCondition {
  '#context': 'ConditionDocument'
  'left_hand': LeftHand
  'operator': ConditionOperator
  'right_hand': RightHand
}

export const functionArgumentMapping: Record<FunctionNames, FunctionArgument>
  = {
    [FunctionNames.MakeDateWithInterval]: {
      '#context': FunctionArgumentType.Period,
      'defaultValue': '',
    },
  }

export const FunctionEntityFieldMapping: Record<FunctionNames, EntityFields[]>
  = {
    [FunctionNames.MakeDateWithInterval]: [EntityFields.Date],
  }
export enum ConditionOperator {
  EQUALS = 'EQUALS',
  GREATER_THEN = 'GREATER_THEN',
  LESS_THEN = 'LESS_THEN',
}
export const entityFieldOperatorMapping: Record<
  EntityFields,
  ConditionOperator[]
> = {
  [EntityFields.String]: [ConditionOperator.EQUALS],
  [EntityFields.Boolean]: [ConditionOperator.EQUALS],
  [EntityFields.Date]: [
    ConditionOperator.EQUALS,
    ConditionOperator.GREATER_THEN,
  ],
  [EntityFields.Number]: [ConditionOperator.EQUALS, ConditionOperator.GREATER_THEN, ConditionOperator.LESS_THEN],
  [EntityFields.Currency]: [ConditionOperator.EQUALS],
}

export function functionNamesOptions(
  t: (v: string) => string,
  entityField?: EntityFields,
): {
    label: string
    value: FunctionNames
  }[] {
  return entityField
    ? Object.entries(FunctionNames)
      .filter(([_, value]) => !/^[A-Z]/.test(value))
      .filter(([_, value]) => {
        const supportedFunctionsArray = Object.keys(
          FunctionEntityFieldMapping,
        ).reduce((accumulator, functionName: FunctionNames) => {
          if (
            FunctionEntityFieldMapping[functionName].includes(entityField)
          ) {
            accumulator.push(functionName)
          }
          return accumulator
        }, [] as FunctionNames[])
        return supportedFunctionsArray.includes(value as FunctionNames)
      })
      .map(([key, value]) => ({
        label: t(`automations.function.options.${value}.label`),
        value: FunctionNames[key as keyof typeof FunctionNames],
      }))
    : []
}
