import type { RouteRecordRaw } from 'vue-router'

export const CollectionRoutes: RouteRecordRaw[] = [

]

// children: [
//   {
//     path: 'templates',
//     name: 'templates',
//     meta: {
//       navbar: {
//         name: 'templates',
//         icon: 'pi-th-large',
//       },
//     },
//     children: [
//       {
//         path: 'email-templates',
//         name: 'email-templates',
//         meta: {
//           navbar: {
//             name: 'email-templates',
//             icon: 'pi-at',
//           },
//         },
//         component: EmailTemplatesPage,
//       },

export default CollectionRoutes
