import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { ref } from 'vue'
import { useAuthStore } from '../auth'

export const loadingDayjs = ref(true)
const languages: Record<string, any> = {
  en: async () => await import('dayjs/locale/en'),
  nl: async () => await import('dayjs/locale/nl'),
  fr: async () => await import('dayjs/locale/fr'),
  de: async () => await import('dayjs/locale/de'),
  es: async () => await import('dayjs/locale/es'),
  it: async () => await import('dayjs/locale/it'),
}

export async function setLocale() {
  try {
    const { userPreferences } = useAuthStore()
    const localeInput = userPreferences.value.locale
    const lang = localeInput.toLowerCase()
    if (lang in languages) {
      await languages[lang]()
    }
    else {
      await languages.en()
    }
    dayjs.locale(lang)
    dayjs.tz.setDefault(userPreferences.value.timezone)
  }
  catch (e) {
    console.log(e)
  }
  finally {
    loadingDayjs.value = false
  }
}

export async function initDayjs() {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(relativeTime)
  dayjs.extend(duration)
  dayjs.extend(weekday)
  dayjs.extend(customParseFormat)
  dayjs.extend(localeData)
  await setLocale()
}
