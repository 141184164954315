import { type InferType, lazy, mixed, object, string, ValidationError } from 'yup'

import type { ComputedRef, Ref } from 'vue'
import {
  automationValueSimpleObjectSchema,
} from '../../../components/Features/automations/edit/Editor/Condition/Value/condition_value.interface.ts'

import { AbstractValueTypeEnum } from './documents.interface.ts'
import type { HydraContext } from '../../../common'

export function referenceValueObjectSchema(t: (v: string) => string) {
  return object({
    'reference': string()
      .required()
      .test('is-not-empty', t('common.forms.required'), (value) => {
        return value !== undefined
      }),
    '#type': mixed()
      .oneOf([AbstractValueTypeEnum.ReferenceValue])
      .default(AbstractValueTypeEnum.ReferenceValue)
      .required(t('common.forms.required')),
  })
}

export type ReferenceValueObjectSchema = InferType<
  ReturnType<typeof referenceValueObjectSchema>
>

export function fixedValueObjectSchema(
  t: (v: string) => string,
  { required = true }: { required?: boolean } = {},
) {
  return object({
    'value': required
      ? mixed()
        .required()
        .test('is-not-empty', t('common.forms.required'), (value) => {
          return value !== undefined
        })
      : mixed(),
    '#type': mixed()
      .oneOf([AbstractValueTypeEnum.FixedValue])
      .default(AbstractValueTypeEnum.FixedValue)
      .required(t('common.forms.required')),
  })
}

export type FixedValueObjectSchema = InferType<
  ReturnType<typeof fixedValueObjectSchema>
>

export function entityReferenceObjectSchema(t: (v: string) => string) {
  return object({
    'iri': string()
      .required()
      .test('is-not-empty', t('common.forms.required'), (value) => {
        return value !== undefined
      }),
    '#type': mixed()
      .oneOf([AbstractValueTypeEnum.EntityReference])
      .default(AbstractValueTypeEnum.EntityReference)
      .required(t('common.forms.required')),
  })
}

export type EntityReferenceValueObjectSchema = InferType<
  ReturnType<typeof entityReferenceObjectSchema>
>

export function expressionValueObjectSchema(t: (v: string) => string) {
  return object({
    'expression': string()
      .required()
      .test('is-not-empty', t('common.forms.required'), (value) => {
        return value !== undefined
      }),
    '#type': mixed()
      .oneOf([AbstractValueTypeEnum.ExpressionValue])
      .default(AbstractValueTypeEnum.ExpressionValue)
      .required(t('common.forms.required')),
  })
}

export type ExpressionValueObjectSchema = InferType<
  ReturnType<typeof expressionValueObjectSchema>
>

export enum StringTemplateType {
  Iri = 'type_iri',
  String = 'type_string',
}

export function stringTemplateTypeOptions(t: ReturnType<typeof useI18n>['t']) {
  return (Object.values(StringTemplateType) as string[])
    .filter(v => v.includes('_'))
    .map(value => ({
      value,
      label: t(
        `common.document.Value.StringTemplate.templateType.options.${value}`,
      ),
    }))
}

export enum RendererType {
  Text = 1,
  Html = 2,
}

export function renderTypeOptions(t: ReturnType<typeof useI18n>['t']) {
  return (Object.values(RendererType) as string[])
    .filter(v => typeof v === 'number')
    .map(value => ({
      value,
      label: t(
        `common.document.Value.StringTemplate.renderer.options.${value}`,
      ),
    }))
}

export function stringTemplateObjectSchema(
  t: (v: string) => string,
  { defaultRenderer = RendererType.Html }: {
    locale?: string
    defaultRenderer?: RendererType
  } = {},
) {
  return object({
    // 'template': string()
    //   .required()
    //   .test('is-not-empty', t('common.forms.required'), (value) => {
    //     return value !== undefined
    //   }),
    'template': automationValueSimpleObjectSchema(t, {
      allowedTypes: [
        AbstractValueTypeEnum.EntityReference,
        AbstractValueTypeEnum.FixedValue,
      ],
    }),
    'locale': automationValueSimpleObjectSchema(t, {
      allowedTypes: [
        AbstractValueTypeEnum.ReferenceValue,
        AbstractValueTypeEnum.FixedValue,
      ],
    }),
    'renderer': mixed()
      .oneOf(Object.values(RendererType).filter(v => typeof v === 'number'))
      .default(defaultRenderer)
      .required(t('common.forms.required')),
    '#type': mixed()
      .oneOf([AbstractValueTypeEnum.StringTemplate])
      .default(AbstractValueTypeEnum.StringTemplate)
      .required(t('common.forms.required')),
  })
}

export type StringTemplateObjectSchema = InferType<
  ReturnType<typeof stringTemplateObjectSchema>
>

export type ValueObjectSchemas =
  | ReferenceValueObjectSchema
  | FixedValueObjectSchema
  | ExpressionValueObjectSchema
  | StringTemplateObjectSchema
  | EntityReferenceValueObjectSchema

export function valueObjectSchemaLazy(t: (v: string) => string, {
  overrideSchema,
}: {
  overrideSchema?: Partial<Record<AbstractValueTypeEnum, any>>
} = {}) {
  return lazy((value) => {
    switch (value?.['#type']) {
      case AbstractValueTypeEnum.ReferenceValue:
        return (
          overrideSchema?.[AbstractValueTypeEnum.ReferenceValue]
          ?? referenceValueObjectSchema(t)
        )
      case AbstractValueTypeEnum.FixedValue:
        return (
          overrideSchema?.[AbstractValueTypeEnum.FixedValue]
          ?? fixedValueObjectSchema(t)
        )
      case AbstractValueTypeEnum.EntityReference:
        return (
          overrideSchema?.[AbstractValueTypeEnum.EntityReference]
          ?? entityReferenceObjectSchema(t)
        )
      case AbstractValueTypeEnum.ExpressionValue:
        return (
          overrideSchema?.[AbstractValueTypeEnum.ExpressionValue]
          ?? expressionValueObjectSchema(t)
        )
      case AbstractValueTypeEnum.StringTemplate:
        return (
          overrideSchema?.[AbstractValueTypeEnum.StringTemplate]
          ?? stringTemplateObjectSchema(t)
        )
      default:
        // Define a schema that will always fail validation for the default case
        return object({
          '#type': mixed()
            .oneOf(
              Object.values(AbstractValueTypeEnum).filter(v =>
                v.includes('.'),
              ),
            )
            .required(t('common.forms.required')),
        }).test('invalid-conditionType', 'Invalid conditionType', (_) => {
          return new ValidationError('Invalid condition type')
        })
    }
  })
}

export function valueObjectSchema(
  t: (v: string) => string,
  {
    overrideSchema,
  }: {
    overrideSchema?: Partial<Record<AbstractValueTypeEnum, any>>
  } = {},
) {
  return object({
    schema: valueObjectSchemaLazy(t, { overrideSchema }),
  })
}

export type ValueObjectSchema = InferType<ReturnType<typeof valueObjectSchema>>

export function getSchemaFromDocumentType(
  documentType: AbstractValueTypeEnum,
  t: ReturnType<typeof useI18n>['t'],
) {
  const { userPreferences } = useAuthStore()

  switch (documentType) {
    case AbstractValueTypeEnum.ReferenceValue:
      return referenceValueObjectSchema(t)
    case AbstractValueTypeEnum.FixedValue:
      return fixedValueObjectSchema(t)
    case AbstractValueTypeEnum.ExpressionValue:
      return expressionValueObjectSchema(t)
    case AbstractValueTypeEnum.StringTemplate:
      return stringTemplateObjectSchema(t, {
        locale: userPreferences.value?.locale
          ? userPreferences.value?.locale.toUpperCase()
          : 'NL',
      })
  }

  return null
}

export function getInitialValuesFromDocumentType(
  documentType: AbstractValueTypeEnum,
  t: ReturnType<typeof useI18n>['t'],
): { schema: ValueObjectSchemas } {
  const schema = getSchemaFromDocumentType(documentType, t)
  let extraDefaults: any = {}
  if (documentType === AbstractValueTypeEnum.StringTemplate) {
    const defaultStringTemplate: Partial<StringTemplateObjectSchema> = {
      template: {
        '#type': AbstractValueTypeEnum.EntityReference,
      },
      locale: {
        '#type': AbstractValueTypeEnum.FixedValue,
      },
    }
    extraDefaults = {
      ...defaultStringTemplate,
    }
  }
  return {
    schema: {
      ...(schema
        ? schema.getDefault()
        : {
            '#type': documentType,
          }),
      ...extraDefaults,
    },
  } as any
}

export async function getHydraContextViaStringTemplate(
  cache: Ref<Record<string, HydraContext>>,
  stringTemplateSchema: StringTemplateObjectSchema,
  onError: ReturnType<typeof useServerErrorHandler>['onError'],
) {
  if (stringTemplateSchema?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
    const entityReference = stringTemplateSchema.template as EntityReferenceValueObjectSchema
    if (entityReference.iri && !cache.value[entityReference.iri]) {
      try {
        const { data } = await api.get<HydraContext>(entityReference.iri)
        cache.value[entityReference.iri] = data
      }
      catch (e) {
        onError(e)
        throw e
      }
    }
  }
}

export function getStringTemplateStringValue(
  t: ReturnType<typeof useI18n>['t'],
  cache?: Ref<Record<string, HydraContext>> | ComputedRef<Record<string, HydraContext>> | Ref<Record<string, HydraContext> | undefined> | ComputedRef<Record<string, HydraContext> | undefined>,
  value?: StringTemplateObjectSchema | null,
  returnEmpty: boolean = false,
  returnIriAsString: boolean = false,
) {
  if (!value?.template) {
    return ''
  }
  const template = value.template
  if (template['#type'] === AbstractValueTypeEnum.FixedValue) {
    const value = (template as FixedValueObjectSchema).value

    if (!value) {
      return ''
    }

    const trimmedValue = value
      .replace(/<[^>]+(>|$)/g, '')
      .replace(/[{}]/g, '')
      .replace(/&nbsp;/g, ' ')
      .substring(0, 20)
    if (value.length > 20) {
      return `${trimmedValue}...`
    }
    else {
      return trimmedValue
    }
  }
  const iriSchema = (template as EntityReferenceValueObjectSchema).iri

  if (!iriSchema) {
    return returnEmpty ? '' : t('common.unknown')
  }

  if (returnIriAsString) {
    return `Template: ${iriToId(iriSchema)}`
  }

  const cachedValue = cache?.value?.[iriSchema]
  return cachedValue && 'name' in cachedValue
    ? (cachedValue.name as string)
    : returnEmpty ? '' : t('common.unknown')
}
