import type { InternalAxiosRequestConfig } from 'axios'
import { InterceptorType } from '../../createApi.ts'
import type { InterceptorConfig } from '../../createApi.ts'

export function JwtBearerTokenRequestInterceptor(
  tokenGetter: () => string,
  excludedRoutes: string[] = [],
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url)
      return config

    if (excludedRoutes.includes(config.url))
      return config

    config.headers.Authorization = `Bearer ${tokenGetter()}`

    return config
  }

  return {
    name: 'JwtBearerTokenRequestInterceptor',
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  }
}
