import PQueue from 'p-queue'
import type { ComputedRef, Ref } from 'vue'
import { AbstractValueTypeEnum } from '../../../../composables'
import { AutomationActionTypesEnum } from './Editor/Action/automation_action_edit.interface.ts'
import { AutomationConditionTypesEnum } from './Editor/Condition/automation_condition_edit.interface.ts'
import { findNode } from './Editor/Mapping/mapping.transformer.ts'
import { MappingTypesEnum } from './Editor/Mapping/MappingTypesEnum.ts'
import type { HydraCollection, HydraContext } from '../../../../common'
import type {
  EntityReferenceValueObjectSchema,
  FixedValueObjectSchema,
  ReferenceValueObjectSchema,
  StringTemplateObjectSchema,
} from '../../../../composables'
import type { AutomationV1ObjectSchema } from './automationv1.interface.ts'
import type { AutomationActionObjectSchema } from './Editor/Action/automation_action_edit.interface.ts'
import type {
  AutomationCreateEmailQueueJobActionObjectSchema,
} from './Editor/Action/CreateEmailQueueJobAction/CreateEmailQueueJobAction.interface.ts'
import type {
  AutomationCreatePrintQueueJobActionObjectSchema,
} from './Editor/Action/CreatePrintQueueJobAction/CreatePrintQueueJobAction.interface.ts'
import type {
  AutomationCreateSmsQueueJobActionObjectSchema,
} from './Editor/Action/CreateSmsQueueJobAction/CreateSmsQueueJobAction.interface.ts'
import type { AutomationArrayConditionObjectSchema } from './Editor/Condition/ArrayCondition/ArrayCondition.interface.ts'
import type { AutomationConditionObjectSchema } from './Editor/Condition/automation_condition_edit.interface.ts'
import type {
  AutomationBooleanConditionObjectSchema,
} from './Editor/Condition/BooleanCondition/BooleanCondition.interface.ts'
import type {
  AutomationDateTimeConditionObjectSchema,
} from './Editor/Condition/DateTimeCondition/DateTimeCondition.interface.ts'
import type { AutomationNullConditionObjectSchema } from './Editor/Condition/NullCondition/NullCondition.interface.ts'
import type {
  AutomationNumericConditionObjectSchema,
} from './Editor/Condition/NumericCondition/NumericCondition.interface.ts'
import type {
  AutomationStringConditionObjectSchema,
} from './Editor/Condition/StringCondition/StringCondition.interface.ts'
import type { MappedEntity } from './Editor/Mapping/mapping.interface.ts'

export function useInitOriginalValueCache({
  upsert,
  mappedEntity,
  automation,
  setArrayCache,
  cache,
}: {
  automation: ComputedRef<AutomationV1ObjectSchema>
  cache: Ref<Record<string, HydraContext>>
  arrayCache: Ref<Record<string, HydraContext[]>>
  upsert: (key: string, value: HydraContext) => void
  setArrayCache: (key: string, value: HydraContext[]) => void
  mappedEntity: ComputedRef<MappedEntity | null>
}) {
  const { t } = useI18n()

  const init = async () => {
    console.log('[originalCache]:', ' Initializing original value cache')

    const queue = new PQueue({
      concurrency: 3,
    })
    let error: Error | undefined

    const fetchQueue = async (endpoint: string, type: 'normal' | 'array') => {
      queue
        .add(async () => {
          if (type === 'normal') {
            const { data } = await api.get<HydraContext>(endpoint)
            upsert(endpoint, data)
          }
          else {
            const { data }
              = await api.get<HydraCollection<HydraContext>>(endpoint)
            setArrayCache(endpoint, data['hydra:member'])
          }
        })
        .then()
        .catch((e) => {
          error = e
        })
    }

    const getValueFromReferenceAndFixed = async (
      reference: any,
      fixedValue: any,
    ): Promise<void | null> => {
      const needleValue = (fixedValue as FixedValueObjectSchema).value
      const haystackNode = findNode(
        t,
        mappedEntity.value,
        (reference as ReferenceValueObjectSchema).reference,
      )
      // noinspection SuspiciousTypeOfGuard
      if (
        haystackNode
        && haystackNode.type === MappingTypesEnum.LAZY_DROPDOWN
        && typeof needleValue == 'number'
      ) {
        const endpoint = haystackNode.getItem(needleValue)
        if (cache.value[endpoint]) {
          return
        }
        try {
          await fetchQueue(endpoint, 'normal')
        }
        catch {
          console.error('Failed to fetch data from endpoint:', endpoint)
          return null
        }
      }
    }

    const updateCacheFromIri = async (endpoint: string) => {
      if (!/^\/api\/.*\/\d+$/.test(endpoint)) {
        return
      }
      if (cache.value[endpoint]) {
        return
      }
      await fetchQueue(endpoint, 'normal')
    }

    const getValueFromTemplate = async (schema: any): Promise<void> => {
      if (schema['#type'] === AbstractValueTypeEnum.StringTemplate) {
        const stringTemplateSchema = schema as StringTemplateObjectSchema

        if (stringTemplateSchema.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          const schema = stringTemplateSchema.template as EntityReferenceValueObjectSchema
          try {
            await updateCacheFromIri(schema.iri)
          }
          catch {
            console.error('Failed to fetch data from endpoint:', schema.iri)
          }
        }
      }
    }

    const getContentIdentifiersFromTemplate = async (
      endpoint: string,
    ): Promise<void> => {
      try {
        if (!/^\/api\/.*\/\d+$/.test(endpoint)) {
          return
        }

        const cIIri = `/api/correspondence_templates/${iriToId(endpoint)}/content_identifiers`

        if (cache.value[cIIri]) {
          return
        }
        await fetchQueue(cIIri, 'array')
      }
      catch {
        console.error('Failed to fetch data from endpoint:', endpoint)
      }
    }

    const getMediaObjectFromSchema = async (schema: any): Promise<void> => {
      if (schema['#type'] === AbstractValueTypeEnum.EntityReference) {
        const stringTemplateSchema = schema as EntityReferenceValueObjectSchema

        const endpoint = stringTemplateSchema.iri
        try {
          if (!/^\/api\/.*\/\d+$/.test(endpoint)) {
            return
          }

          if (cache.value[endpoint]) {
            return
          }
          await fetchQueue(endpoint, 'normal')
        }
        catch {
          console.error('Failed to fetch data from endpoint:', endpoint)
        }
      }
    }

    const initRightOrLeftValue = async (
      schema:
        | AutomationStringConditionObjectSchema
        | AutomationNumericConditionObjectSchema
        | AutomationDateTimeConditionObjectSchema
        | AutomationBooleanConditionObjectSchema
        | AutomationNullConditionObjectSchema,
    ): Promise<void> => {
      if (
        'leftValue' in schema
        && '#type' in schema.leftValue
        && schema.leftValue['#type'] === AbstractValueTypeEnum.StringTemplate
      ) {
        await getValueFromTemplate(schema.leftValue)
      }

      if (
        'rightValue' in schema
        && '#type' in schema.rightValue
        && schema.rightValue['#type'] === AbstractValueTypeEnum.StringTemplate
      ) {
        await getValueFromTemplate(schema.rightValue)
      }

      if (
        'leftValue' in schema
        && '#type' in schema.leftValue
        && schema.leftValue['#type'] === AbstractValueTypeEnum.ReferenceValue
        && (schema.leftValue as ReferenceValueObjectSchema).reference
        && 'rightValue' in schema
        && '#type' in schema.rightValue
        && schema.rightValue['#type'] === AbstractValueTypeEnum.FixedValue
        && (schema.rightValue as FixedValueObjectSchema).value
      ) {
        await getValueFromReferenceAndFixed(
          schema.leftValue,
          schema.rightValue,
        )
      }

      if (
        'rightValue' in schema
        && '#type' in schema.rightValue
        && schema.rightValue['#type'] === AbstractValueTypeEnum.ReferenceValue
        && (schema.rightValue as ReferenceValueObjectSchema).reference
        && 'leftValue' in schema
        && '#type' in schema.leftValue
        && schema.leftValue['#type'] === AbstractValueTypeEnum.FixedValue
        && (schema.leftValue as FixedValueObjectSchema).value
      ) {
        await getValueFromReferenceAndFixed(
          schema.rightValue,
          schema.leftValue,
        )
      }
    }
    for (const c of automation.value.conditions) {
      const condition = c as AutomationConditionObjectSchema['condition']
      if (condition['#type'] === AutomationConditionTypesEnum.ArrayCondition) {
        const schema = condition as AutomationArrayConditionObjectSchema
        if (
          schema.haystack['#type'] === AbstractValueTypeEnum.ReferenceValue
          && (schema.haystack as ReferenceValueObjectSchema).reference
          && schema.needleOrValue['#type'] === AbstractValueTypeEnum.FixedValue
          && (schema.needleOrValue as FixedValueObjectSchema).value
        ) {
          await getValueFromReferenceAndFixed(
            schema.haystack,
            schema.needleOrValue,
          )
          await getValueFromTemplate(schema.haystack)
          await getValueFromTemplate(schema.needleOrValue)
        }
      }
      if (condition['#type'] === AutomationConditionTypesEnum.StringCondition) {
        await initRightOrLeftValue(
          condition as AutomationStringConditionObjectSchema,
        )
      }
      if (condition['#type'] === AutomationConditionTypesEnum.NullCondition) {
        await initRightOrLeftValue(
          condition as AutomationNullConditionObjectSchema,
        )
      }
      if (
        condition['#type'] === AutomationConditionTypesEnum.NumericCondition
      ) {
        await initRightOrLeftValue(
          condition as AutomationNumericConditionObjectSchema,
        )
      }
      if (
        condition['#type'] === AutomationConditionTypesEnum.DateTimeCondition
      ) {
        await initRightOrLeftValue(
          condition as AutomationDateTimeConditionObjectSchema,
        )
      }
      if (
        condition['#type'] === AutomationConditionTypesEnum.BooleanCondition
      ) {
        await initRightOrLeftValue(
          condition as AutomationBooleanConditionObjectSchema,
        )
      }
    }

    for (const a of automation.value.actions) {
      const action = a as AutomationActionObjectSchema['action']

      if (
        action['#type'] === AutomationActionTypesEnum.CreateEmailQueueJobAction
      ) {
        const schema
          = action as AutomationCreateEmailQueueJobActionObjectSchema

        if (schema.body?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          await updateCacheFromIri((schema.body?.template as EntityReferenceValueObjectSchema).iri)
          await getContentIdentifiersFromTemplate((schema.body?.template as EntityReferenceValueObjectSchema).iri)
        }
        if (schema.parts) {
          for (const part of schema.parts) {
            await getMediaObjectFromSchema(part)
          }
        }
        if (schema.embeddedParts) {
          for (const embeddedPart of schema.embeddedParts) {
            await getMediaObjectFromSchema(embeddedPart.mediaObject)
          }
        }
      }

      if (
        action['#type'] === AutomationActionTypesEnum.CreateSmsQueueJobAction
      ) {
        const schema
          = action as AutomationCreateSmsQueueJobActionObjectSchema
        if (schema.body?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          await updateCacheFromIri((schema.body?.template as EntityReferenceValueObjectSchema).iri)
          await getContentIdentifiersFromTemplate((schema.body?.template as EntityReferenceValueObjectSchema).iri)
        }
      }
      if (
        action['#type'] === AutomationActionTypesEnum.CreatePrintQueueJobAction
      ) {
        const schema
          = action as AutomationCreatePrintQueueJobActionObjectSchema
        if (schema.body?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          await updateCacheFromIri((schema.body?.template as EntityReferenceValueObjectSchema).iri)
          await getContentIdentifiersFromTemplate((schema.body?.template as EntityReferenceValueObjectSchema).iri)
        }
      }
    }
    await queue.onIdle()

    if (error) {
      console.error('Failed to fetch data from endpoint:', error)
      throw error
    }
    console.log('[originalCache]:', ' Finished initializing original value cache')
  }

  return {
    init,
  }
}
