export enum MappingTypesEnum {
  PARENT = 'parent',
  BOOLEAN = 'boolean',
  STRING = 'string',
  STRING_ARRAY = 'string_array',
  CURRENCY = 'currency',
  NUMBER = 'number',
  ROOT_PARENT = 'root_parent',
  DROPDOWN = 'dropdown',
  LAZY_DROPDOWN = 'lazy_dropdown',
  DATE = 'date',
}
