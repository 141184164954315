import {
  useAdministrationStore,
} from '../../../../../../composables/features/administrations/useAdministrationStore.ts'
import { MappingTypesEnum } from './MappingTypesEnum.ts'
import type { MappedEntity } from './mapping.interface.ts'

export const HydraDatesMap: MappedEntity = {
  createdAt: {
    type: MappingTypesEnum.DATE,
    label: 'common.createdAt.label',
    placeholder: 'common.createdAt.placeholder',
  },
  updatedAt: {
    type: MappingTypesEnum.DATE,
    label: 'common.updatedAt.label',
    placeholder: 'common.updatedAt.placeholder',
  },
}

export const NameMap: MappedEntity = {
  name: {
    type: MappingTypesEnum.STRING,
    label: 'common.name.label',
    placeholder: 'common.name.placeholder',
  },
}
export const DescriptionMap: MappedEntity = {
  description: {
    type: MappingTypesEnum.STRING,
    label: 'common.description.label',
    placeholder: 'common.description.placeholder',
  },
}

export const AdministrationMap: MappedEntity = {
  administration: {
    type: MappingTypesEnum.PARENT,
    label: 'administrations.label',
    children: {
      id: {
        type: MappingTypesEnum.DROPDOWN,
        options: () => useAdministrationStore().administrationsOptionsIdBased.value,
        placeholder: 'administrations.placeholder',
        hasPerms: () => useAdministrationStore().allowAdministrationsRead.value,
        label: 'common.id.label',
      },
      ...NameMap,
      ...HydraDatesMap,
    },
  },
}
