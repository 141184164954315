import { AxiosError } from 'axios'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

class ServerError extends AxiosError {
  constructor(
    config: InternalAxiosRequestConfig,
    request: any,
    response: AxiosResponse,
        message: string = 'base.api.server_error',
        code: string = '0',
  ) {
    super(message, code, config, request, response)
    this.message = message
    this.name = 'ServerError'
    this.status = response.status
    this.config = config
    this.request = request
    this.response = response
    if ('captureStackTrace' in Error)
      Error.captureStackTrace(this, ServerError)
  }

  status: number
  config: InternalAxiosRequestConfig
  request: any
  response: AxiosResponse
}

export {
  ServerError,
}
